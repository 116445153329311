import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AnLiListView from '../views/AnLiListView.vue'
import AnLiInfoView from '../views/AnLiInfoView.vue'
import SheJiShiListView from '../views/SheJiShiListView.vue'
import SheJiShiInfoView from '../views/SheJiShiInfoView.vue'
import ShiGongDuiListView from '../views/ShiGongDuiListView.vue'
import ShiGongDuiInfoView from '../views/ShiGongDuiInfoView.vue'
import YouXuanView from '../views/YouXuanView.vue'
import GuanYuWoMen from '../views/GuanYuWoMen.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },{
    path: '/anli/list',
    name: 'anLiList',
    component: AnLiListView
  },{
    path: '/anli/info',
    name: 'anLiInfo',
    component: AnLiInfoView
  },{
    path: '/shejishi/list',
    name: 'sheJiShiList',
    component: SheJiShiListView
  },{
    path: '/shejishi/info',
    name: 'sheJiShiInfo',
    component: SheJiShiInfoView
  },{
    path: '/shigongdui/list',
    name: 'shiGongDuiList',
    component: ShiGongDuiListView
  },{
    path: '/shigongdui/info',
    name: 'shiGongDuiInfo',
    component: ShiGongDuiInfoView
  },{
    path: '/youxuan',
    name: 'YouXuan',
    component: YouXuanView
  },{
    path: '/about',
    name: 'about',
    component: GuanYuWoMen
  },
  {
    path: '/about1111',
    name: 'about1111',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
