<template>
  <div class="home">
    <div class="banner"></div>
    <!--立即预约-->
    <div class="yuYueBox">
      <div class="left">
        <div class="leftBox">
          <img src="../../public/img/icon1_1.png" />
          <div class="leftBoxText">一站全包</div>
        </div>
        <div class="leftBox">
          <img src="../../public/img/icon1_2.png" />
          <div class="leftBoxText">设计施工</div>
        </div>
        <div class="leftBox">
          <img src="../../public/img/icon1_3.png" />
          <div class="leftBoxText">服务承诺</div>
        </div>
        <div class="leftBox">
          <img src="../../public/img/icon1_4.png" />
          <div class="leftBoxText">免费设计</div>
        </div>
      </div>
      <div class="right">
        <el-form
          :model="yuYueForm"
          :rules="yuYueRules"
          ref="yuYueForm"
          label-width="0px"
        >
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item prop="mianJi">
                <el-input
                  tabindex="0"
                  v-model="yuYueForm.mianJi"
                  placeholder="请输入您的房屋面积"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="xingMing">
                <el-input
                  v-model="yuYueForm.xingMing"
                  placeholder="请输入您的姓名"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item prop="dianHua">
            <el-input
              v-model="yuYueForm.dianHua"
              placeholder="请输入您的联系电话"
            ></el-input>
          </el-form-item>
          <el-button :disabled="yuYueForm.submiting" @click="submitForm('yuYueForm')" type="success"
            >立即预约</el-button
          >
        </el-form>
      </div>
    </div>
    <!--整包服务-->
    <div class="zbnrBox">
      <div class="left">
        <div class="zhengBao1">整包</div>
        <div class="zhengBao2">服务内容</div>
      </div>
      <div class="right">
        <div class="item">
          <div class="text">
            <div class="text1">设计费全免</div>
            <div class="text2">全程免费为您量房设计</div>
          </div>
          <div class="icon icon1"><img src="../../public/img/icon2_1.png"/></div>
        </div>
        <div class="item floatRight">
          <div class="text">
            <div class="text1">95%还原设计</div>
            <div class="text2">按照图纸施工，最终效果还原度可达95%</div>
          </div>
          <div class="icon icon2"><img src="../../public/img/icon2_2.png"/></div>
        </div>
        <div class="item marginTop">
          <div class="text">
            <div class="text1">施工提速30%</div>
            <div class="text2">优化各个流程节点，整体施工时间提速30%</div>
          </div>
          <div class="icon icon3"><img src="../../public/img/icon2_3.png"/></div>
        </div>
        <div class="item floatRight marginTop">
          <div class="text">
            <div class="text1">二年质保</div>
            <div class="text2">项目交付后仍有两年质保，售后无忧</div>
          </div>
          <div class="icon icon4"><img src="../../public/img/icon2_4.png"/></div>
        </div>
        <div class="item width100 marginTop">
          <div class="text">
            <div class="text1">损耗全包节约10%成本</div>
            <div class="text2">损耗由我们承担，相当于您节约10&的成本</div>
          </div>
          <div class="icon icon5"><img src="../../public/img/icon2_5.png"/></div>
        </div>
      </div>
    </div>
    <!--实景案例-->
    <div class="sjalBox">
      <div class="title">每年近<span style="color:#FF9300">2000</span>个实景案例</div>
      <div class="subTitle">包含了各种风格的建筑及装修</div>
      <div class="imgs">
        <el-image class="img" 
              v-on:mouseup="goToAnLiPage(item.id)"
              v-for="item in anLiData.list" 
              :key="item.id"
              :src="item.coverImage"
            />
        <!--
        <div class="item" v-on:mouseup="goToAnLiPage(item.id)" v-for="item in anLiData.list" :key="item.id">
          <img :src="item.coverImage" />
        </div>
        -->
        <!--
        <div class="item"><img src="../../public/img/img1_0.png" /></div>
        -->
      </div>
      <div style="clear: both"></div>
    </div>
    <!--设计团队-->
    <div class="sjtdBox">
      <div class="title">设计团队</div>
      <div class="subTitle">上百位设计师，擅长各种风格的设计</div>
      <div class="imgs">
        <div class="itemBox1">
          <div class="item" v-for='item in sheJiShiData.box1' :key="item.id" :style="{backgroundImage: 'url('+item.avatar+')'}">
            <div class="name">{{item.name}}</div>
            <div class="tips">从业{{item.operationYear}}年</div>
          </div>
          <!--
          <div class="item" style="background-image: url(/img/img2_2.png);">
            <div class="name">张五一</div>
            <div class="tips">从业8年</div>
          </div>
          -->
          <div style="clear: both"></div>
        </div>
        <div class="itemBox2">
          <div class="item" v-for='item in sheJiShiData.box2' :key="item.id" :style="{backgroundImage: 'url('+item.avatar+')'}">
            <div class="name">{{item.name}}</div>
            <div class="tips">从业{{item.operationYear}}年</div>
          </div>
          <!--
          <div class="item" style="background-image: url(/img/img2_1.png);">
            <div class="name">张五一</div>
            <div class="tips">从业8年</div>
          </div>
          -->
          <div style="clear: both"></div>
        </div>
      </div>
      <div style="clear: both"></div>
    </div>
    <!--施工团队-->
    <div class="sgtdBox">
      <div class="title">施工团队</div>
      <div class="subTitle">已承包上百个项目，擅长各种风格的设计</div>
      <div class="imgs">
        <div 
          v-for='(item,index) in shiGongDuiData.list' 
          :key="item.id" 
          :class="(index==0?'item1':'item2') + (index==2||index==3?' marginTop':'')" 
          :style="{backgroundImage: 'url('+item.backgroundImage.split(',')[0]+')'}">
        </div>
        <!--
        <div class="item1" style="background-image: url(/img/img3_1.png);"></div>
        <div class="item2" style="background-image: url(/img/img3_2.png);"></div>
        <div class="item2 marginTop" style="background-image: url(/img/img3_3.png);"></div>
        <div class="item2 marginTop" style="background-image: url(/img/img3_4.png);"></div>
        -->
      </div>
      <div style="clear: both"></div>
    </div>
    <!--服务流程-->
    <div class="fwlcBox">
      <div class="title">服务流程</div>
      <div class="imgs">
        <div class="item">
          <div class="img"><img src="../../public/img/icon4_1.png" /></div>
          <div class="name">在线预约</div>
        </div>
        <div class="item">
          <div class="img"><img src="../../public/img/icon4_2.png" /></div>
          <div class="name">初步设计</div>
        </div>
        <div class="item">
          <div class="img"><img src="../../public/img/icon4_3.png" /></div>
          <div class="name">精准报价</div>
        </div>
        <div class="item">
          <div class="img"><img src="../../public/img/icon4_4.png" /></div>
          <div class="name">签约合同</div>
        </div>
        <div class="item">
          <div class="img"><img src="../../public/img/icon4_5.png" /></div>
          <div class="name">精准收工</div>
        </div>
        <div class="item">
          <div class="img"><img src="../../public/img/icon4_6.png" /></div>
          <div class="name">验收交付</div>
        </div>
        <div class="item">
          <div class="img"><img src="../../public/img/icon4_7.png" /></div>
          <div class="name">无忧售后</div>
        </div>
        
      </div>
      <div style="clear: both"></div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import DesignerAPI from "../api/designerAPI";
import CompanyAPI from "../api/companyAPI";
import ReservationLogAPI from "../api/reservationLogAPI";
import AnLiAPI from "../api/anLiAPI";
import { Message } from "element-ui";

export default Vue.extend({
  name: "HomeView",
  components: {},
  created: function(){
    //实景案例
    AnLiAPI.getHomeList().then(result =>{
      console.log(result);
      if(result.status == 200 && result.data.code == 0){
        this.$data.anLiData.list = result.data.data.list;
      }
    }).catch(error => {

    });
    //加载设计师
    DesignerAPI.list(1,10).then(result => {
      console.log(result);
      if(result.status == 200 && result.data.code == 0){
        if(result.data.data.list.length <= 5){
          this.$data.sheJiShiData.box1 = result.data.data.list;
        }else{
          this.$data.sheJiShiData.box1 = result.data.data.list.slice(0,5);
          this.$data.sheJiShiData.box2 = result.data.data.list.slice(5,result.data.data.list.length);
        }
      }
    }).catch(error =>{
      console.log(error)
    });
    //加载施工队
    CompanyAPI.list(1,4).then(result => {
      console.log(result);
      if(result.status == 200 && result.data.code == 0){
        this.$data.shiGongDuiData.list = result.data.data.list;
      }
      console.log("this.$data.shiGongDuiData.list:",this.$data.shiGongDuiData.list)
    }).catch(error =>{
      console.log(error)
    });
  },
  methods: {
    goToAnLiPage(id){
      this.$router.push('/anli/info?id='+id);
      window.scrollTo({
        top: 0,
        left: 0,
        //behavior: 'smooth' // 平滑滚动
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$data.yuYueForm.submiting = true;
          ReservationLogAPI.create(
            0, 0, 
            this.$data.yuYueForm.mianJi, 
            this.$data.yuYueForm.xingMing, 
            this.$data.yuYueForm.dianHua)
          .then(result =>{
            console.log(result);
            setTimeout(() => {
              this.$data.yuYueForm.submiting = false;
            }, 1000);
            if(result.status == 200 && result.data.code == 0){
              Message.success("预约成功！");
            }else{
              Message.error(result.data.msg);
            }
          });
        } else {
          console.log("error submit!!");
          setTimeout(() => {
              this.$data.yuYueForm.submiting = false;
            }, 1000);
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  data() {
    return {
      //设计案例
      anLiData:{
        list:[]
      },
      //设计师数据
      sheJiShiData:{
        box1:[],
        box2:[]
      },
      //施工队数据
      shiGongDuiData:{
        list:[]
      },
      //立即预约
      yuYueForm: {
        mianJi: null,
        xingMing: null,
        dianHua: null,
        submiting:false,
      },
      yuYueRules: {
        mianJi: [
          { required: true, message: "请输入您的房屋面积", trigger: "blur" },
        ],
        xingMing: [
          { required: true, message: "请输入您的姓名", trigger: "blur" },
        ],
        dianHua: [
          { required: true, message: "请输入您的联系电话", trigger: "blur" },
        ],
      },
    };
  },
});
</script>
<style lang="scss">
/*顶部banner********************/
.home .banner {
  width: 19.2rem;
  height: 9.25rem;
  background-color1111111: aqua;
  background-image: url(../../public/img/homeBanner1.png);
  background-repeat: round;
}

/*立即预约********************/
.home .yuYueBox {
  height: 3.5rem;
  background: #e3e3e3;
  margin: 0 1rem;
  margin-top: 0.78rem;
  .left {
    float: left;
    width: 3.8rem;
    border-left11111: 1px solid #1c9a0f;
    border-right11111: 1px solid #1c9a0f;
    padding-top: 0.09rem;
    padding-left: 1.53rem;
    height: 3.4rem;
    .leftBox {
      float: left;
      width: 1.53rem;
      height: 1.25rem;
      text-align: center;
      font-size: 0.16rem;
      background-color: #ffffff;
      margin-top: 0.3rem;
      margin-left: 0.3rem;
      img {
        width: 0.56rem;
        height: 0.56rem;
        padding-top: 0.18rem;
      }
      .leftBoxText {
        padding-top: 0.07rem;
      }
    }
  }
  .right {
    border-left1111: 1px solid #1c9a0f;
    border-right1111: 1px solid #1c9a0f;
    float: right;
    width: 8.58rem;
    padding-right: 1.83rem;
    padding-top: 0.43rem;
    .rightInput1 {
      width: 4rem;
      float: left;
    }
    .el-form-item__content {
      line-height: 0.68rem;
    }
    .el-form-item {
      margin-bottom: 0.32rem;
    }
    .el-form-item__error {
      padding-top: 0.04rem;
      font-size: 0.15rem;
    }
    .el-input__inner {
      height: 0.63rem;
      border-radius: 0px;
      font-size: 0.17rem;
    }
    .el-button {
      border-radius: 0px;
      background: #71be33;
      padding: 0px;
      width: 100%;
      height: 0.63rem;
      font-size: 0.17rem;
      display: block;
    }
  }
}

/*整包服务内容**********开始**********/
.home .zbnrBox {
  height: 3.68rem;
  margin: 0 1rem;
  margin-top: 0.78rem;
  .left {
    float: left;
    background-image: url(../../public/img/bg2_1.png);
    background-repeat: round;
    width: 3.9rem;
    height: 3.68rem;
    font-weight: 500;
    .zhengBao1 {
      color: #95DE5A;
      font-size: 0.55rem;
      margin-left: 1rem;
      padding-top: 1.1rem;
    }
    .zhengBao2 {
      color: #ffffff;
      font-size: 0.46rem;
      margin-left: 1rem;
    }
  }
  .right {
    float: right;
    background-color11111: #1c9a0f;
    width: 12rem;
    .item {
      background-color: #EFEFEF;
      width: 5.5rem;
      height: 1.1rem;
      float: left;
      .text {
        float: left;
        width: 4rem;
        margin-left: 0.4rem;
        margin-top: 0.21rem;
        .text1 {
          font-size: 0.28rem;
          color: #333333;
        }
        .text2 {
          font-size: 0.19rem;
          color: #7B7B7B;
        }
      }
      .icon {
        float: right;
        margin-right: 0.2rem;
        margin-top: 0.24rem;
      }
      .icon1 {
        img {
          width: 0.61rem;
          height: 0.61rem;
        }
      }
      .icon2 {
        img {
          width: 0.78rem;
          height: 0.61rem;
        }
      }
      .icon3 {
        img {
          width: 0.62rem;
          height: 0.61rem;
        }
      }
      .icon4{
        img {
          width: 0.58rem;
          height: 0.74rem;
        }
      }
      .icon5{
        img {
          width: 0.55rem;
          height: 0.55rem;
        }
      }
    }
    .marginTop {
      margin-top: 0.19rem;
    }
    .floatRight {
      float: right;
    }
    .width100 {
      width: 100%;
    }
  }
}

/*实景案例**********开始**********/
.home .sjalBox {
  margin: 0 1rem;
  margin-top: 0.78rem;
  .title {
    text-align: center;
    font-size: 0.5rem;
    font-weight: 600;
    color: #333333;
  }
  .subTitle {
    text-align: center;
    font-weight: 400;
    font-size: 0.28rem;
    color: #7E7E7E;
  }
  .imgs {
    height: 5.1rem;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    display111: flex;
    margin-top: 0.3rem;
    display: inline-block;
    white-space: nowrap;
    .img {
      height: 5.1rem;
      cursor: pointer;
      display: inline-block;
      margin-right: 0.5rem;
    }
    .item {
      cursor: pointer;
      float: left;
      height: 3rem;
      width: 4.7rem;
      height11: 5.11rem;
      width11: 4.55rem;
      margin-right: 0.5rem;
      img {
        height: 3rem;
        width: 4.7rem;
      }
    }
  }
}

/*设计团队**********开始**********/
.home .sjtdBox {
  margin: 0 1rem;
  margin-top: 0.78rem;
  .title {
    text-align: center;
    font-size: 0.5rem;
    font-weight: 600;
    color: #333333;
  }
  .subTitle {
    text-align: center;
    font-weight: 400;
    font-size: 0.28rem;
    color: #7E7E7E;
  }
  .imgs {
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    margin-top: 0.3rem;
    .itemBox1 {
      height: 3.21rem;
      background-color11111: #71be33;
      display: flex;
    }
    .itemBox2 {
      margin-top: 0.2875rem;
      height: 3.21rem;
      width: 100%;
      background-color111111: #333333;
      display: flex;
    }
    .item {
      float: left;
      height: 3.21rem;
      width: 3.21rem;
      margin-right: 0.2875rem;
      background-repeat: repeat; /* 平铺背景 */
      background-size: cover; /* 覆盖整个容器 */
      color: #FFFFFF;
      cursor: pointer;
      .name {
        float: left;
        padding-left: 0.25rem;
        font-weight: 600;
        font-size: 0.24rem;
        margin-top: 2.7rem;
      }
      .tips {
        float: right;
        padding-right: 0.25rem;
        font-size: 0.19rem;
        margin-top: 2.74rem;
      }
    }
  }
}

/*施工团队**********开始**********/
.home .sgtdBox {
  margin: 0 1rem;
  margin-top: 0.78rem;
  .title {
    text-align: center;
    font-size: 0.5rem;
    font-weight: 600;
    color: #333333;
  }
  .subTitle {
    text-align: center;
    font-weight: 400;
    font-size: 0.28rem;
    color: #7E7E7E;
  }
  .imgs {
    width: 100%;
    margin-top: 0.3rem;
    .item1 {
      width: 11.55rem;
      height: 6.81rem;
      float: left;
      background-repeat: repeat; /* 平铺背景 */
      background-size: cover; /* 覆盖整个容器 */
      cursor: pointer;
    }
    .item2 {
      width: 5.37rem;
      height: 2.1rem;
      float: right;
      background-repeat: repeat; /* 平铺背景 */
      background-size: cover; /* 覆盖整个容器 */
      cursor: pointer;
    }
    .marginTop {
      margin-top: 0.255rem;
    }
  }
}

/*服务流程**********开始**********/
.home .fwlcBox {
  height: 5.39rem;
  background-image: url(../../public/img/img4_1.png);
  margin: 0 1rem;
  margin-top: 0.78rem;
  background-repeat: round;
  margin-bottom111111: 0.78rem;
  .title {
    font-size: 0.5rem;
    text-align: center;
    color: #ffffff;
    font-weight: 600;
    padding-top: 0.7rem;
  }
  .imgs {
    margin-left: 0.6rem;
    margin-top: 1rem;
    .item {
      float: left;
      width: 2.14rem;
      margin-right: 0.18rem;
      .img {
        text-align: center;
        img {
          height: 0.6rem;
        }
      }
      .name {
        height: 0.7rem;
        width: 2.14rem;
        line-height: 0.7rem;
        text-align: center;
        font-weight: 500;
        font-size: 0.3rem;
        color: #FFFFFF;
        background-image: url(../../public/img/bg4_1.png);
        background-repeat: repeat; /* 平铺背景 */
        background-size: cover; /* 覆盖整个容器 */
        margin-top: 0.21rem
      }
    }
  }
}
</style>
