<template>
  <div class="anli">
    <!--顶部图片-->
    <div class="banner">
      <div>{{banner.text}}</div>
    </div>
    <!--搜索框-->
    <div class="search">
      <el-input placeholder="请输入内容" v-model="search.keyword" class="input-with-select">
        <el-button slot="append" @click="doSearch" icon="el-icon-search"></el-button>
      </el-input>
    </div>
    <!--筛选条件-->
    <div class="filterBox">
      <div class="filterBar">
        <div class="lable">风格：</div>
        <div class="values">
          <div v-on:mousedown="filterClink('fengGe','')" :class="('value'+(filter.fengGe.value==''?' active':''))">全部</div>
          <div 
            v-for="(item,index) in filter.fengGe.list" 
            v-on:mousedown="filterClink('fengGe',item.value)"
            :key="index" 
            :class="('value'+(filter.fengGe.value===item.value?' active':''))">
              {{item.label}}
          </div>
        </div>
        <div style="clear: both"></div>
      </div>
      <div class="filterBar">
        <div class="lable">进深：</div>
        <div class="values">
          <div v-on:mousedown="filterClink('jinShen','')" :class="('value'+(filter.jinShen.value==''?' active':''))">全部</div>
          <div 
            v-for="(item,index) in filter.jinShen.list" 
            v-on:mousedown="filterClink('jinShen',item.value)"
            :key="index" 
            :class="('value'+(filter.jinShen.value===item.value?' active':''))">
              {{item.label}}
          </div>
        </div>
      </div>
      <div class="filterBar">
        <div class="lable">面宽：</div>
        <div class="values">
          <div v-on:mousedown="filterClink('mianKuan','')" :class="('value'+(filter.mianKuan.value==''?' active':''))">全部</div>
          <div 
            v-for="(item,index) in filter.mianKuan.list" 
            v-on:mousedown="filterClink('mianKuan',item.value)"
            :key="index" 
            :class="('value'+(filter.mianKuan.value===item.value?' active':''))">
              {{item.label}}
          </div>
        </div>
      </div>
      <div class="filterBar">
        <div class="lable">层数：</div>
        <div class="values">
          <div v-on:mousedown="filterClink('chengShu','')" :class="('value'+(filter.chengShu.value==''?' active':''))">全部</div>
          <div 
            v-for="(item,index) in filter.chengShu.list" 
            v-on:mousedown="filterClink('chengShu',item.value)"
            :key="index" 
            :class="('value'+(filter.chengShu.value===item.value?' active':''))">
              {{item.label}}
          </div>
        </div>
      </div>
      <div class="filterBar">
        <div class="lable">造价：</div>
        <div class="values">
          <div v-on:mousedown="filterClink('zaoJia','')" :class="('value'+(filter.zaoJia.value==''?' active':''))">全部</div>
          <div 
            v-for="(item,index) in filter.zaoJia.list" 
            v-on:mousedown="filterClink('zaoJia',item.value)"
            :key="index" 
            :class="('value'+(filter.zaoJia.value===item.value?' active':''))">
              {{item.label}}
          </div>
        </div>
      </div>
    </div>
    <!--内容显示-->
    <div class="contentBox">
      <div class="contentBody">

        <div :class="('contentItem'+ ((index%3==1||index%3==2)?' contentItemMargin':''))" v-for="(item,index) in pageDate.list" :key="item.id">
          <div class="img" v-on:mouseup="goToAnLiPage(item.id)" :style="{backgroundImage:'url('+item.coverImage+')'}"></div>
          <div class="nameBox">
            <div class="name" v-on:mouseup="goToAnLiPage(item.id)" >{{ item.title }}</div>
            <div class="lookTimes">
              <div class="times">{{ item.playerNum }}</div>
              <div class="icon"></div>
            </div>
          </div>
          <div class="other">
            <!--风格-->
            <div class="style" v-if="item.style != ''">{{ item.style }}</div>
            <!--层数-->
            <div class="cengShu" v-if="item.layers>0">{{ item.layers }}层</div>
          </div>
          <div class="userInfo">
            <!--头像-->
            <div class="headerImg" v-if="item.userInfoRespVO != null" :style="{backgroundImage: 'url('+item.userInfoRespVO.avatar+')'}"></div>
            <!--昵称-->
            <div class="nickName" v-if="item.userInfoRespVO != null">{{ item.userInfoRespVO.nickname }}</div>
            <!--发布日期-->
            <div class="date">{{ item.createTime | formatDate }}</div>
          </div>
        </div>

        <div style="clear: both;"></div>
        <div class="pageInfo" style="text-align: right; padding: 0.5rem 0;">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="currentChange"
            :current-page="pageDate.pageNo"
            :page-size="pageDate.pageSize"
            :total="pageDate.total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Message } from "element-ui";
import DictDataAPI from "../api/dictDataAPI";
import AnLiAPI from "../api/anLiAPI";

export default Vue.extend({
  name: "AnLiListView",
  components: {},
  created: function(){
    //加载筛选条件
    this.loadFilterData();
    //更新内容
    this.getDataList();
  },
  watch: {
    '$route.query': {
      immediate: true, // 初始化时立即触发
      handler(newQuery, oldQuery) {
        //子导航发生变化
        this.onTypeUpdate(newQuery.type);
      }
    }
  },
  methods: {
    goToAnLiPage(id){
      var url  = "";//window.location.hostname;
      url += '#/anli/info?id='+id;
      window.open(url, '_blank');
      /*
      this.$router.push('/anli/info?id='+id);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth' // 平滑滚动
      });
      */
    },
    doSearch: function(){
      //更新内容
      this.getDataList();
    },
    currentChange: function(current){
      this.$data.pageDate.pageNo = current;
      //更新内容
      this.getDataList();
    },
    getDataList: function(){
      AnLiAPI.searchList(
        this.$data.pageDate.pageNo,
        this.$data.pageDate.pageSize,
        this.$data.search.keyword,
        this.$data.banner.type,
        this.$data.filter.fengGe.value,
        this.$data.filter.jinShen.value,
        this.$data.filter.mianKuan.value,
        this.$data.filter.chengShu.value,
        this.$data.filter.zaoJia.value
      ).then(result => {
        console.log(result);
        if(result.status != 200){
          Message.error("数据加载失败！");
          return;
        }
        if(result.data.code != 0){
          Message.error(result.data.msg);
          return;
        }
        this.$data.pageDate.list = result.data.data.list;
        this.$data.pageDate.total = result.data.data.total;
      }).catch(error => {
        Message.error(error);
      });
    },
    //加载处理所有筛选条件
    loadFilterData:function(){
      DictDataAPI.getMapByTypes(['business_dynamics_style','business_dynamics_building_long','business_dynamics_layers','business_dynamics_budget'],(dateMap)=>{
        console.log(dateMap);
        this.$data.filter.fengGe.list = dateMap['business_dynamics_style'];
        this.$data.filter.jinShen.list = dateMap['business_dynamics_building_long'];
        this.$data.filter.mianKuan.list = dateMap['business_dynamics_building_long'];
        this.$data.filter.chengShu.list = dateMap['business_dynamics_layers'];
        this.$data.filter.zaoJia.list = dateMap['business_dynamics_budget'];
      },(error)=>{
        console.log(error);
      });
    },
    filterClink:function(type, value){
      console.log(type,value);
      console.log(this.$data.filter[type]);
      this.$data.filter[type].value = value;
      //更新内容
      this.getDataList();
    },
    //处理子导航发生变化
    onTypeUpdate: function(newType){
      //console.log("onTypeUpdate:", newType);
      if(newType != null && typeof newType != undefined && this.$data.banner.type != newType){
        var textArray = ['全部案例', '整包方案','精品案例','局部改造','新建案例'];
        if(newType < 0 || newType > textArray.length-1){
          this.$data.banner.type = 0;
          this.$data.banner.text = "全部案例";
        }else{
          this.$data.banner.type = newType;
          this.$data.banner.text = textArray[newType];
        }
        this.$data.pageDate.pageNo = 1;
        //更新内容
        this.getDataList();
      }
    },
  },
  data() {
    return {
      banner:{
        type:0, //0全部案例，1整包方案，2精品案例，3局部改造，4新建案例
        text:'全部案例'
      },
      //搜索框
      search: {
        keyword:""
      },
      pageDate:{
        list:[],
        pageNo:1,
        pageSize:12,
        total:0
      },
      //搜索过滤条件
      filter: {
        fengGe:{//风格
          value:"",
          list:[]
        },
        jinShen:{//进深
          value:"",
          list:[]
        },
        mianKuan:{//面宽
          value:"",
          list:[]
        },
        chengShu:{//层数
          value:"",
          list:[]
        },
        zaoJia:{//造价
          value:"",
          list:[]
        }
      },
    };
  },
});
</script>
<style lang="scss">
/*顶部banner********************/
.anli .banner {
  width: 19.2rem;
  height: 2.67rem;
  font-weight: bold;
  font-size: 0.82rem;
  text-align: center;
  line-height: 2.67rem;
  color: #FFFFFF;
  background-color111111: aqua;
  background-image: url(../../public/img/img5_1.png);
  background-repeat: repeat; /* 平铺背景 */
  background-size: cover; /* 覆盖整个容器 */
}

.anli .search{
  margin: 0 1rem;
  margin-top: 0.58rem;
  text-align: right;
  .el-input {
    width: 3.61rem;
    min-width: 140px;
    .el-button {
      font-size1111: 0.2rem;
    }
    .el-input__inner {
      font-size: 0.2rem;
      height: 0.5rem;
      line-height: 0.5rem;
      min-height: 24px;
    }
  }
}

.anli .filterBox {
  margin: 0 1rem;
  margin-top: 0.1rem;
  .filterBar {
    border-bottom: 1px solid #E8E8E8;
    height: 1rem;
    .lable {
      float: left;
      font-size: 0.21rem;
      font-weight: 600;
      color: #333333;
      padding-top: 0.44rem;
    }
    .values {
      float: right;
      padding-top: 0.4rem;
      height: 0.6rem;
      width: 16.5rem;
      .value {
        cursor: pointer;
        float: left;
        font-size: 0.19rem;
        line-height: 0.22rem;
        color: #8A8A8A;
        margin-right: 0.33rem;
        padding: 0.09rem 0.09rem 0.06rem 0.09rem;
      }
      .active {
        background-color: #71BE33;
        border-radius: 0.03rem;
        color: #FFFFFF;
      }
    }
  }
}

.anli .contentBox{
  margin-top: 0.58rem;
  background-color: #F8F8F8;
  margin-bottom1111: 0.78rem;
  .contentBody{
    margin: 0 1rem;
    .contentItemMargin {
      margin-left: 0.395rem;
    }
    .contentItem {
      float: left;
      width: 5.47rem;
      height: 5.6rem;
      background: #FFFFFF;
      border-radius: 10px 10px 10px 10px;
      margin-top: 0.395rem;
      .img {
        cursor: pointer;
        width: 5.47rem;
        height: 3.48rem;
        background: #E8E8E8;
        border-radius: 10px 10px 0px 0px;
        background-repeat: round;
      }
      .nameBox{
        line-height: 0.32rem;
        padding-right:0.3rem;
        padding-left:0.3rem;
        padding-top: 0.3rem;
        background-color11111: #287DF1;
        height: 0.3rem;
        overflow: hidden;
        .name {
          cursor: pointer;
          float: left;
          font-weight: 600;
          font-size: 0.23rem;
          color: #333333;
        }
        .lookTimes {
          float: right;
          .icon {
            float: right;
            background-image: url(../../public/img/icon5_1.png);
            width: 0.22rem;
            height: 0.14rem;
            margin-top: 0.08rem;
            margin-right: 0.05rem;
            background-repeat: repeat; /* 平铺背景 */
            background-size: cover; /* 覆盖整个容器 */
          }
          .times {
            float: right;
            font-weight: 400;
            font-size: 0.16rem;
            color: #9B9B9B;
          }
        }
      }
      .other {
        padding-left: 0.3rem;
        padding-top: 0.1rem;
        background-color111: #71BE33;
        height: 0.3rem;
        overflow11: hidden;
        div {
          float: left;
          font-size: 0.18rem;
          color: #989898;
          padding-right: 0.3rem;
        }
      }
      .userInfo {
        padding-right: 0.3rem;
        padding-left: 0.3rem;
        padding-top: 0.2rem;
        .headerImg {
          float: left;
          width: 0.68rem;
          height: 0.68rem;
          background-repeat: repeat; /* 平铺背景 */
          background-size: cover; /* 覆盖整个容器 */
          border-radius: 0.34rem 0.34rem 0.34rem 0.34rem;
        }
        .nickName {
          float: left;
          font-size: 0.21rem;
          color: #555555;
          line-height: 0.68rem;
          padding-left: 0.1rem;
        }
        .date {
          font-size: 0.18rem;
          color: #9B9B9B;
          float: right;
          line-height: 0.68rem;
        }
      }
    }
    .pageInfo {
      .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: #71BE33;
      }
      .el-pagination.is-background .el-pager li:not(.active):hover {
        color: #71BE33;
      }
    }
  }
}
</style>
