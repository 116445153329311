<template>
  <div class="anliInfo">
    <!--顶部图片-->
    <div class="banner">
      <el-carousel height="9.25rem">
        <el-carousel-item v-for="(img,index) in banner.imgs" :key="index">
          <div class="imgBox">
            <div class="imgBg" :style="{backgroundImage: 'url('+img+')'}"></div>
            <div class="img" :style="{backgroundImage: 'url('+img+')'}"></div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!--标题和副标题-->
    <div class="titleBox">
      <div class="title">{{ anLiInfo.title }}</div>
      <div class="subTitle">{{ anLiInfo.subTitle }}</div>
    </div>
    <!--设计师详情-->
    <div class="shiJiShiBox" v-if="anLiInfo.dynamicsType == 1 && sheJiShi.info != null">
      <div class="headerImg" :style="{backgroundImage: 'url('+sheJiShi.info.avatar+')'}"></div>
      <div class="userInfo">
        <div class="name">{{ sheJiShi.info.name }}</div>
        <div class="baseInfo">
          {{ sheJiShi.info.sex == 1?"男性":"女性" }}
          |
          {{ sheJiShi.info.cityName }}
          |
          从业{{ sheJiShi.info.operationYear }}年
        </div>
        <div class="other" v-if="sheJiShi.info.projectExperience"><span style="font-weight: 600;">项目经验：</span>{{ sheJiShi.info.projectExperience }}</div>
        <div class="other" v-if="sheJiShi.info.prizeProject"><span style="font-weight: 600;">所获奖项：</span>{{ sheJiShi.info.prizeProject }}</div>
        <div class="other" v-if="sheJiShi.info.qualifications"><span style="font-weight: 600;">行业资质：</span>{{ sheJiShi.info.qualifications }}</div>
        <div class="other" v-if="sheJiShi.info.style"><span style="font-weight: 600;">擅长风格：</span>{{ sheJiShi.info.style | replaceStr }}</div>
        <div class="button">
          <el-button @click="YuYueDialogData.visible=true" type="success">立即预约</el-button>
        </div>
      </div>
    </div>
    <!--施工队详情-->
    <div class="shiGongDuiBox" v-if="anLiInfo.dynamicsType == 2 && shiGongDui.info != null">
      <div class="headerImg" :style="{backgroundImage: 'url('+shiGongDui.info.logoImage+')'}"></div>
      <div class="userInfo">
        <div class="info">
          <div class="name">{{ shiGongDui.info.companyName }}</div>
          <div class="other"><span style="font-weight: 600;color: #333333;">营业时间：</span>{{ shiGongDui.info.tradeDate }} </div>
          <div class="other">
            <span style="font-weight: 600;color: #333333;">主营业务：</span>{{ shiGongDui.info.businessContent }}
          </div>
        </div>
        <div class="button">
          <el-button @click="YuYueDialogData.visible=true" type="success">立即预约</el-button>
        </div>
      </div>
      <div class="numbers">
          <div class="numberBox" v-if="shiGongDui.info.companyCount">
            <div class="number">{{ shiGongDui.info.companyCount }}个</div>
            <div class="text">案例作品</div>
          </div>
          <div class="numberBox" v-if="shiGongDui.info.provinceName">
            <div class="number">{{ shiGongDui.info.provinceName }}</div>
            <div class="text">所在地</div>
          </div>
          <div class="numberBox" v-if="shiGongDui.info.scale">
            <div class="number">{{ shiGongDui.info.scale }}年老店</div>
            <div class="text">规模</div>
          </div>
        </div>
    </div>
    <!--推荐内容显示-->
    <div class="SJSFengGeListBox"  v-if="tuiJianList.length > 0">
      <div class="tuiJianTitle">相关风格案例推荐</div>
      <div class="contentBody">
        <div :class="('contentItem'+ ((index%3==1||index%3==2)?' contentItemMargin':''))" v-for="(item,index) in tuiJianList" :key="item.id">
          <div class="img" v-on:mouseup="goToAnLiPage(item.id)" :style="{backgroundImage:'url('+item.coverImage+')'}"></div>
          <div class="nameBox">
            <div class="name" v-on:mouseup="goToAnLiPage(item.id)">{{ item.title }}</div>
            <div class="lookTimes">
              <div class="times">{{ item.playerNum }}</div>
              <div class="icon"></div>
            </div>
          </div>
          <div class="other">
            <!--风格-->
            <div class="style" v-if="item.style != ''">{{ item.style }}</div>
            <!--层数-->
            <div class="cengShu" v-if="item.layers>0">{{ item.layers }}层</div>
          </div>
          <div class="userInfo">
            <!--头像-->
            <div class="headerImg" 
              v-if="item.userInfoRespVO != null" 
              :style="{backgroundImage: 'url('+item.userInfoRespVO.avatar+')'}">
            </div>
            <!--昵称-->
            <div class="nickName" v-if="item.userInfoRespVO != null">
              {{ item.userInfoRespVO.nickname }}
            </div>
            <!--发布日期-->
            <div class="date">{{ item.createTime | formatDate }}</div>
          </div>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>

    <!--预约弹窗-->
    <YuYueDialog v-model="YuYueDialogData"></YuYueDialog>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Message } from "element-ui";
import AnLiAPI from "../api/anLiAPI";

export default Vue.extend({
  name: "AnLiInfoView",
  components: {},
  created: function(){
    
  },
  watch: {
    '$route.query': {
      immediate: true, // 初始化时立即触发
      handler(newQuery, oldQuery) {
        console.log(newQuery);
        this.$data.id = newQuery.id;
        //获取详情
        this.getInfo( () => {
          //获取推荐内容
          this.getList();
        });
      }
    }
  },
  methods: {
    goToAnLiPage(id){
      this.$router.push('/anli/info?id='+id);
      window.scrollTo({
        top: 0,
        left: 0,
        //behavior: 'smooth' // 平滑滚动
      });
    },
    getList: function(){
      if(this.$data.anLiInfo.style != ''){
        AnLiAPI.getTuiJianList(
          this.$data.id, 
          this.$data.anLiInfo.style,
          1,
          9
        ).then(result => {
          console.log(result);
          if(result.status == 200 && result.data.code == 0){
            this.$data.tuiJianList = result.data.data.list;
          }
        });
      }
    },
    //获取详情
    getInfo: function(okBack){
      AnLiAPI.getAnLiInfo(this.$data.id).then(result => {
        console.log(result);
        if(result.status != 200){
          Message.error("接口异常！");
          return;
        }
        if(result.data.code != 0){
          Message.error(result.data.msg);
          return;
        }
        this.$data.anLiInfo = result.data.data;
        this.$data.banner.imgs = [];
        //封面
        if(this.$data.anLiInfo.coverImage != ''){
          this.$data.banner.imgs.push(this.$data.anLiInfo.coverImage);
        }
        //图集
        if(this.$data.anLiInfo.images != ''){
          var imgsTempArray = this.$data.anLiInfo.images.split(',');
          for (let i = 0; i < imgsTempArray.length; i++) {
            const img = imgsTempArray[i];
            this.$data.banner.imgs.push(img);
          }
        }
        //设计师和施工队基本信息赋值
        //dynamicsType: 0:普通动态,1:设计师动态，2施工队动态
        this.$data.sheJiShi.info = null;
        this.$data.shiGongDui.info = null;
        if(this.$data.anLiInfo.dynamicsType == 1 && this.$data.anLiInfo.designerRespVO){//设计师
          this.$data.sheJiShi.info = this.$data.anLiInfo.designerRespVO;
          this.$data.YuYueDialogData.id = this.$data.sheJiShi.info.id;
          this.$data.YuYueDialogData.type = 1;
        }else if(this.$data.anLiInfo.dynamicsType == 2 && this.$data.anLiInfo.companyRespVO){//施工队
          this.$data.shiGongDui.info = this.$data.anLiInfo.companyRespVO;
          this.$data.YuYueDialogData.id = this.$data.shiGongDui.info.id;
          this.$data.YuYueDialogData.type = 2;
        }
        //处理完成回调
        if(okBack){
          okBack();
        }
      }).catch(error => {
        Message.error(error);
      });
    },
  },
  data() {
    return {
      id:0,
      anLiInfo:{},
      banner:{
        imgs:[]
      },
      tuiJianList:[],
      //设计师相关信息
      sheJiShi: {
        //基本信息
        info:{},
      },
      //施工队相关信息
      shiGongDui: {
        //基本信息
        info: {}
      },
      //预约Dialog
      YuYueDialogData:{
        visible:false,
        id:0,//设计师id或施工队id
        type:0//0:首页,1 设计师,2施工队,3 优选整装，4底部AI免费设计
      }
    }
  },
});
</script>
<style lang="scss">
.anliInfo .banner{
  width: 19.2rem;
  height: 9.25rem;
  .imgBox {
    width: 19.2rem;
    height: 9.25rem;
    background-color111: #71BE33;
    position: relative;
    .imgBg {
      width: 19.2rem;
      height: 9.25rem;
      background-repeat: repeat; /* 平铺背景 */
      background-size: cover; /* 覆盖整个容器 */
      position: absolute;
      filter: blur(5px); /* 设置模糊效果 */
    }
    .img {
      width: 19.2rem;
      height: 9.25rem;
      position: absolute;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  
  
}
.anliInfo .titleBox{
  margin: 0 1rem;
  .title {
    font-weight: 600;
    font-size: 0.39rem;
    color: #333333;
    margin-top: 0.5rem;
  }
  .subTitle {
    font-weight: 400;
    font-size: 0.22rem;
    color: #919191;
    line-height: 0.4rem;
  }
}

.anliInfo .shiJiShiBox {
  margin: 0 1rem;
  height: 3.96rem;
  margin-top: 0.7rem;
  .headerImg {
    float: left;
    width: 3.81rem;
    height: 3.96rem;
    background-repeat: round;
  }
  .userInfo {
    float: left;
    height: 100px;
    width: 10rem;
    margin-left: 1rem;
    .name {
      width: 10rem;
      float: left;
      font-weight: 600;
      font-size: 0.37rem;
      color: #333333;
    }
    .baseInfo  {
      line-height: 0.4rem;
      width: 10rem;
      float: left;
      font-weight: 400;
      font-size: 0.19rem;
      color: #838383;
      padding-bottom: 0.2rem;
    }
    .other {
      line-height: 0.4rem;
      width: 10rem;
      float: left;
      font-size: 0.22rem;
      span {
        color: #333333;
      }
      color: #ACACAC;
    }
    .button{
      float: left;
      width: 3rem;
      padding-top: 0.59rem;
      .el-button {
        border-radius: 0px;
        background: #71be33;
        padding: 0px;
        width: 100%;
        height: 0.63rem;
        font-size: 0.17rem;
        display: block;
      }
    }
  }
}
.anliInfo .shiGongDuiBox {
  margin: 0 1rem;
  height: 3.96rem;
  margin-top: 0.7rem;
  .headerImg {
    float: left;
    width: 3.81rem;
    height: 3.96rem;
    background-repeat: round;
    background-color: #F8F8F8;
  }
  .userInfo {
    float: right;
    max-height: 2.9rem;
    min-height: 2rem;
    width: 11.5rem;
    overflow-x: hidden;
    overflow-y: auto;
    background-color11111: #71BE99;
    .info {
      float: left;
      width: 8rem;
      background-color111111: #71BEFF;
      .name {
        font-weight: 600;
        font-size: 0.37rem;
        color: #333333;
        margin-bottom: 0.2rem;
      }
      .other {
        font-size: 0.22rem;
        line-height: 0.4rem;
        margin-top: 0.1rem;
      }
    }
    .button {
      float: right;
      margin-top: 0.6rem;
      .el-button {
        border-radius: 0px;
        background: #71be33;
        padding: 0px;
        width: 2.93rem;
        height: 0.8rem;
        font-size: 0.19rem;
        display: block;
      }
    }
  }
  .numbers {
    float: right;
    width: 11.5rem;
    background-color11111: #71BE33;
    .numberBox {
      float: left;
      width: 3rem;
      background-color11111: #555555;
      margin-top: 0.2rem;
      .number{
        font-weight: 600;
        font-size: 0.37rem;
        color: #E24E00;
      }
      .text {
        font-size: 0.22rem;
        color: #8E8E8E;
      }
    }
  }
}

.anliInfo .SJSFengGeListBox{
  margin-top: 0.58rem;
  background-color: #F8F8F8;
  margin-bottom1111: 0.78rem;
  padding: 0.5rem 0;
  .tuiJianTitle {
    margin-left: 1.4rem;
    font-weight: 600;
    font-size: 0.39rem;
    color: #333333;
  }
  .contentBody{
    margin: 0 1rem;
    .contentItemMargin {
      margin-left: 0.395rem;
    }
    .contentItem {
      float: left;
      width: 5.47rem;
      height: 5.6rem;
      background: #FFFFFF;
      border-radius: 10px 10px 10px 10px;
      margin-top: 0.395rem;
      .img {
        cursor: pointer;
        width: 5.47rem;
        height: 3.48rem;
        background: #E8E8E8;
        border-radius: 10px 10px 0px 0px;
        background-repeat: round;
      }
      .nameBox{
        line-height: 0.32rem;
        padding-right:0.3rem;
        padding-left:0.3rem;
        padding-top: 0.3rem;
        background-color11111: #287DF1;
        height: 0.3rem;
        overflow: hidden;
        .name {
          cursor: pointer;
          float: left;
          font-weight: 600;
          font-size: 0.23rem;
          color: #333333;
        }
        .lookTimes {
          float: right;
          .icon {
            float: right;
            background-image: url(../../public/img/icon5_1.png);
            width: 0.22rem;
            height: 0.14rem;
            margin-top: 0.08rem;
            margin-right: 0.05rem;
            background-repeat: repeat; /* 平铺背景 */
            background-size: cover; /* 覆盖整个容器 */
          }
          .times {
            float: right;
            font-weight: 400;
            font-size: 0.16rem;
            color: #9B9B9B;
          }
        }
      }
      .other {
        padding-left: 0.3rem;
        padding-top: 0.1rem;
        background-color111: #71BE33;
        height: 0.3rem;
        overflow11: hidden;
        div {
          float: left;
          font-size: 0.18rem;
          color: #989898;
          padding-right: 0.3rem;
        }
      }
      .userInfo {
        padding-right: 0.3rem;
        padding-left: 0.3rem;
        padding-top: 0.2rem;
        .headerImg {
          float: left;
          width: 0.68rem;
          height: 0.68rem;
          background-repeat: repeat; /* 平铺背景 */
          background-size: cover; /* 覆盖整个容器 */
          border-radius: 0.34rem 0.34rem 0.34rem 0.34rem;
        }
        .nickName {
          float: left;
          font-size: 0.21rem;
          color: #555555;
          line-height: 0.68rem;
          padding-left: 0.1rem;
        }
        .date {
          font-size: 0.18rem;
          color: #9B9B9B;
          float: right;
          line-height: 0.68rem;
        }
      }
    }
    .pageInfo {
      .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: #71BE33;
      }
      .el-pagination.is-background .el-pager li:not(.active):hover {
        color: #71BE33;
      }
    }
  }
}
</style>
