import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import moment from 'moment/moment';
import axios from "axios"
axios.defaults.baseURL = 'https://api.tumuxiangye.com';
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.common['Authorization'] = 'Bearer 95685131645f40f9b8f441efa08cba2f';

Vue.config.productionTip = false
Vue.use(ElementUI);


import YuYueDialog from "@/components/YuYueDialog.vue";
Vue.component('YuYueDialog', YuYueDialog)
import QRCodeDialog from "@/components/QRCodeDialog.vue";
Vue.component('QRCodeDialog', QRCodeDialog)

Vue.filter('formatDate', function(value, formatString) {
  formatString = formatString || 'YYYY-MM-DD';
  return moment(value).format(formatString);
});
Vue.filter('replaceStr', function(value, findStr, replaceStr) {
  findStr = findStr || ",";
  replaceStr = replaceStr || " | ";
  if(value == null || typeof value == "undefined"){
    return "";
  }
  return value.replace(findStr, replaceStr);
});
Vue.filter('getFirst', function(value, formatString) {
  formatString = formatString || ',';
  if(value==null || value==''){
    return value;
  }
  return value.split(formatString)[0];
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
