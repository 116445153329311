<template>
  <div class="guanYWM">
    <!--banner-->
    <div class="banner"></div>
    <!--公司简介-->
    <div class="jianJie">
      <div class="content">
        <div class="title">公司简介</div>
        <div class="text">
          土木乡野（北京）科技有限公司，其前身是 2008 年成立的北京善域建设发展有限公司，多年来在乡村建设领域砥砺前行，不断开拓创新。<br><br>
          在乡村别墅、民宿、庭院景观以及乡村振兴的规划、设计、施工方面，公司展现出了非凡的实力和独特的优势。100 余位杰出的设计人才深入挖掘乡村的自然与人文之美，将其融入每一个设计细节，让每一座建筑都成为乡村大地上的璀璨明珠。专业的施工管理团队严格把控施工流程，注重每一道工序的质量，从一砖一瓦到整体景观，从室内装饰到室外环境，无不精心打造。</div>
      </div>
      <div class="img"></div>
      <div style="clear: both;"></div>
    </div>
    <!--土木乡野APP-->
    <div class="appInfo">
      <div class="title">土木乡野APP</div>
      <div class="subTitle">线上免费AI设计</div>
      <div class="content">
        <div class="logo"></div>
        <div class="text">随着时代的发展，尤其是在 AI 技术蓬勃兴起的大背景下，公司成功转型为建筑类科技企业，并自主研发了具有开创性的土木乡野 APP 。这款 APP 能满足用户乡村建房、民宿等设计施工需求的全能平台。</div>
      </div>
      <div class="content2">
        <div class="text">土木乡野 APP 整合了公司十多年来积累的上千个精彩的案例，为用户提供了海量的灵感源泉。不仅如此，其强大的 AI 设计功能能够根据用户输入的需求和偏好，迅速生成独具创意且贴合实际的设计方案。同时，平台上汇聚了大量优秀的设计师、经验丰富的施工队以及优质的建材服务商，形成了一个完整且高效的服务生态链。</div>
        <div style="clear: both;"></div>
        <div class="img1"></div>
        <div class="img2"></div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <!--我们的服务-->
    <div class="fuwu">
      <div class="title">我们的服务</div>
      <div class="imgs">
        <div class="img" style="background-image: url(/img/img8_5.png); ">
          <div class="text">专业</div>
        </div>
        <div class="img marginLeft" style="background-image: url(/img/img8_6.png); ">
          <div class="text">品质</div>
        </div>
        <div class="img marginLeft" style="background-image: url(/img/img8_7.png); ">
          <div class="text">信赖</div>
        </div>
      </div>
    </div>
    <!--我们的服务-->
    <div class="chengNuo">
      <div class="title">我们承诺</div>
      <div class="content">
        <div class="text">选择土木乡野，就是选择了专业、品质与信赖。我们坚信，每一个乡村都蕴藏着无限的可能，每一个项目都是对美好生活的精心雕琢。在未来的发展征程中，公司将持续秉承创新、卓越、负责的精神，不断探索前沿技术，提升服务品质，以更加出色的表现和卓越的成果，为乡村振兴事业贡献力量，为客户创造更多令人向往的乡村生活空间。</div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "YouXuanView",
  components: {},
  created: function(){
    
  },
  methods: {
    
  },
  data() {
    return {
      
    }
  },
});
</script>

<style lang="scss">
.guanYWM .banner{
  width: 19.20rem;
  height: 9.25rem;
  background-image: url(../../public/img/img8_1.png);
  background-repeat: round;
}
.guanYWM .jianJie {
  margin: 0 1rem;
  margin-top: 0.5rem;
  .content {
    width: 10.4rem;
    float: left;
    .title {
      font-weight: 600;
      font-size: 0.5rem;
      color: #333333;
      margin-bottom: 0.3rem;
    }
    .text {
      font-weight: 400;
      font-size: 0.22rem;
      color: #333333;
      line-height: 0.33rem;
    }
  }
  .img {
    float: right;
    width: 5.45rem;
    height: 3.42rem;
    background: #71BE33;
  }
}
.guanYWM .appInfo {
  margin: 0 1rem;
  margin-top: 1rem;
  .title {
    font-weight: 600;
    font-size: 0.5rem;
    color: #333333;
    text-align: center;
  }
  .subTitle {
    font-weight: 400;
    font-size: 0.28rem;
    color: #7E7E7E;
    text-align: center;
    margin-top: 0.1rem;
  }
  .content {
    height: 5.33rem;  
    background-image: url(../../public/img/img8_2.png);
    background-repeat: round;
    margin-top: 0.2rem;
    .logo {
      float: left;
      width: 3.14rem;
      height: 3.14rem;
      background-image: url(../../public/img/logo3.png);
      background-repeat: repeat; /* 平铺背景 */
      background-size: cover; /* 覆盖整个容器 */
      background-color: #FFFFFF;
      border-radius: 1.57rem;
      margin-top: 1.095rem;
      margin-left: 1.095rem;
    }
    .text {
      float: right;
      font-weight: 400;
      font-size: 0.22rem;
      color: #FFFFFF;
      width: 7rem;
      margin-right: 0.78rem;
      margin-top: 2rem;
      line-height: 0.3rem;
      
    }
  }
  .content2 {
    position: relative;
    margin-top: 0.78rem;
    .text {
      float: left;
      font-weight: 400;
      font-size: 0.25rem;
      line-height: 0.45rem;
      color: #333333;
      width: 6.65rem;
      margin: 1rem 0 0.5rem 0.5rem
    }
    .img1 {
      height: 3.96rem;
      background-image: url(../../public/img/img8_4.png);
      background-repeat: round;
    }
    .img2 {
      top: 0rem;
      right: 0rem;
      position: absolute;
      width: 6.96rem;
      height: 8.04rem;
      background-image: url(../../public/img/img8_3.png);
      background-repeat: round;
    }
  }
}
.guanYWM .fuwu {
  margin: 0 1rem;
  margin-top: 1rem;
  .title{
    font-weight: 600;
    font-size: 0.5rem;
    color: #333333;
    text-align: center;
  }
  .imgs {
    height: 4.53rem;
    margin-top: 0.5rem;
    .marginLeft{
      margin-left: 1.505rem;
    }
    .img {
      width: 4.73rem;
      height: 4.53rem;
      float: left;
      background-repeat: round;
      .text {
        line-height: 4.53rem;
        text-align: center;
        font-weight: 600;
        font-size: 0.8rem;
        color: #FFFFFF;
      }
    }
  }
}
.guanYWM .chengNuo {
  margin: 0 1rem;
  margin-top: 0.78rem;
  .title {
    font-weight: 600;
    font-size: 0.5rem;
    color: #333333;
    text-align: center;
  }
  .content {
    margin-top: 0.3rem;
    height: 4.04rem;
    background-image: url(../../public/img/img8_8.png);
    background-repeat:round;
    .text {
      padding: 0 1rem;
      font-weight: 400;
      font-size: 0.25rem;
      color: #FFFFFF;
      padding-top: 1.4rem;
    }
  }
}
</style>
