<template>
  <div class="sheJiShi">
    <!--顶部图片-->
    <div class="banner">
      <div>施工团队</div>
    </div>
    <!--搜索框-->
    <div class="search">
      <el-input placeholder="请输入内容" v-model="search.keyword" class="input-with-select">
        <el-button slot="append" @click="doSearch" icon="el-icon-search"></el-button>
      </el-input>
    </div>
    <!--筛选条件-->
    <div class="filterBox">
      <div class="filterBar">
        <div class="lable">经营年限：</div>
        <div class="values">
          <div v-on:mousedown="filterClink('nianXian','')" :class="('value'+(filter.nianXian.value==''?' active':''))">全部</div>
          <div 
            v-for="(item,index) in filter.nianXian.list" 
            v-on:mousedown="filterClink('nianXian',item.value)"
            :key="index" 
            :class="('value'+(filter.nianXian.value===item.value?' active':''))">
              {{item.label}}
          </div>
        </div>
      </div>
      <div class="filterBar">
        <div class="lable">区域：</div>
        <div class="values">
          <div v-on:mousedown="onProvinceChange('',false);filterClink('province','');" :class="('value'+(filter.province.value==''?' active':''))">全部</div>
          <el-select :class="('selectValue'+ (filter.province.value==''?'':' selectActive'))" clearable  @change="onProvinceChange" v-model="filter.province.value" placeholder="一级地区">
            <el-option
              v-for="item in filter.province.list"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <el-select :class="('selectValue'+ (filter.city.value==''?'':' selectActive'))" clearable @change="onCityChange" v-model="filter.city.value" placeholder="二级地区">
            <el-option
              v-for="item in filter.city.list"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <el-select  :class="('selectValue'+ (filter.area.value==''?'':' selectActive'))" clearable  @change="onAreaChange" v-model="filter.area.value" placeholder="三级地区">
            <el-option
              v-for="item in filter.area.list"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>

    </div>
    <!--内容列表-->
    <div class="contentBox">
      <div class="contentItems">

        <div class="contentItem" v-for="(item, index) in pageDate.list" :key="index">
          <div class="headerImg" v-on:mouseup="goToShiGongDuiInfoPage(item.id)" :style="{backgroundImage: 'url('+item.logoImage+')'}"></div>
          <div class="userInfo">
            <div class="name" v-on:mouseup="goToShiGongDuiInfoPage(item.id)" >{{ item.companyName }}</div>
            <div class="otherBox">
              <div class="other" v-if="item.tradeDate!=null&&item.tradeDate!=''"><span>营业时间：</span>{{ item.tradeDate }}</div>
              <div class="other" v-if="item.businessContent!=null&&item.businessContent!=''"><span>主营业务：</span>{{ item.businessContent }}</div>
              <div class="other" v-if="item.scale!=null&&item.scale!=''"><span>规模：</span>{{ item.scale }}年老店</div>
              <div class="other" v-if="item.style!=null&&item.style!=''"><span>擅长风格：</span>{{ item.style | replaceStr }}</div>
            </div>
            <div class="numberBox">
              <div class="numberItem">
                <div class="number">{{ item.companyCount }}个</div>
                <div class="title">案例作品</div>
              </div>
              <div class="numberItem">
                <div class="number">{{ item.operationYear }}年</div>
                <div class="title">经营年限</div>
              </div>
              <div class="numberItem" v-if="item.provinceName!=null&&item.provinceName!=''">
                <div class="number">{{ item.provinceName }}</div>
                <div class="title">所在区域</div>
              </div>
            </div>
          </div>
          <div class="button">
            <el-button @click="YuYueDialogData.id=item.id;YuYueDialogData.visible=true" type="success">立即预约</el-button>
          </div>
          <div style="clear: both;"></div>
          <div class="imgs">
            <el-image class="img" 
              v-on:mouseup="goToAnLiInfoPage(itemImg.id)"
              v-for="(itemImg, itemIndex) in item.dynamicsList" 
              :key="itemIndex" 
              :src="itemImg.coverImage"
            />
          </div>
        </div>

      </div>
      <div style="clear: both;"></div>
      <div class="pageInfo" style="text-align: right;">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="currentChange"
          :current-page="pageDate.pageNo"
          :page-size="pageDate.pageSize"
          :total="pageDate.total">
        </el-pagination>
      </div>
    </div>

    <!--预约弹窗-->
    <YuYueDialog v-model="YuYueDialogData"></YuYueDialog>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Message } from "element-ui";
import DictDataAPI from "../api/dictDataAPI";
import CompanyAPI from "../api/companyAPI";
import AreaAPI from "../api/areaAPI";

export default Vue.extend({
  name: "SheJiShiListView",
  components: {},
  created: function(){
    //加载筛选条件
    this.loadFilterData();
    //加载地区树
    this.loadAreaTree();
    //更新内容
    this.getDataList();
  },
  methods: {
    onProvinceChange(newValue, isReloadData){
      //console.log(newValue,isReloadData);
      if(isReloadData == undefined){
        isReloadData = true;
      }
      this.$data.filter.province.value = newValue;
      //更新二级地区
      //下级列表
      this.$data.filter.city.list = [];
      if(newValue != null){
        for (let index = 0; index < this.$data.filter.province.list.length; index++) {
          const element = this.$data.filter.province.list[index];
          if(element.id == newValue){
            this.$data.filter.city.list = element.children;
          }
        }
      }
      //下级值
      this.onCityChange('', false);
      //更新内容
      if(isReloadData){
        this.getDataList();
      }
    },
    onCityChange(newValue, isReloadData){
      //console.log(newValue);
      if(isReloadData == undefined){
        isReloadData = true;
      }
      this.$data.filter.city.value = newValue;
      //修改三级地区
      //下级列表
      this.$data.filter.area.list = [];
      if(newValue != null){
        for (let index = 0; index < this.$data.filter.city.list.length; index++) {
          const element = this.$data.filter.city.list[index];
          if(element.id == newValue){
            this.$data.filter.area.list = element.children;
          }
        }
      }
      //下级值
      this.onAreaChange('', false);
      //更新内容
      if(isReloadData){
        this.getDataList();
      }
    },
    onAreaChange(newValue, isReloadData){
      //console.log(newValue);
      if(isReloadData == undefined){
        isReloadData = true;
      }
      this.$data.filter.area.value = newValue;
      //更新内容
      if(isReloadData){
        this.getDataList();
      }
    },
    loadAreaTree(){
      AreaAPI.getTree().then(result => {
        if(result.status == 200 && result.data.code == 0){
          console.log(result);
          this.$data.filter.province.list = result.data.data;
        }
      });
    },
    goToShiGongDuiInfoPage(id){
      var url  = "";//window.location.hostname;
      url += '#/shigongdui/info?id='+id;
      window.open(url, '_blank');
    },
    goToAnLiInfoPage(id){
      var url  = "";//window.location.hostname;
      url += '#/anli/info?id='+id;
      window.open(url, '_blank');
    },
    doSearch: function(){
      //更新内容
      this.getDataList();
    },
    currentChange: function(current){
      this.$data.pageDate.pageNo = current;
      //回顶部
      window.scrollTo({
        top: 0,
        left: 0,
        //behavior: 'smooth' // 平滑滚动
      });
      //更新内容
      this.getDataList();
    },
    getDataList: function(){
      CompanyAPI.list(
        this.$data.pageDate.pageNo,
        this.$data.pageDate.pageSize, 
        this.$data.filter.nianXian.value, 
        this.$data.filter.province.value, 
        this.$data.filter.city.value, 
        this.$data.filter.area.value,
        this.$data.search.keyword
      ).then(result => {
          console.log(result);
          if(result.status != 200){
            Message.error("列表加载失败！");
            return;
          }
          if(result.data.code != 0){
            Message.error(result.data.msg);
            return;
          }
          this.$data.pageDate.list = result.data.data.list;
          this.$data.pageDate.total = result.data.data.total;
          console.log(this.$data.pageDate.list);
        }).catch(error => {
          Message.error(error);
      });
    },
    //加载处理所有筛选条件
    loadFilterData:function(){
      DictDataAPI.getMapByTypes(['business_working_years'],(dateMap)=>{
        console.log(dateMap);
        this.$data.filter.nianXian.list = dateMap['business_working_years'];
      },(error)=>{
        console.log(error);
      });
    },
    filterClink:function(type, value){
      //console.log(type,value);
      //console.log(this.$data.filter[type]);
      this.$data.filter[type].value = value;
      //更新内容
      this.getDataList();
    }
  },
  data() {
    return {
      //预约Dialog
      YuYueDialogData:{
        visible:false,
        id:0,//设计师id或施工队id
        type:2//0:首页,1 设计师,2施工队,3 优选整装
      },
      //搜索框
      search: {
        keyword:""
      },
      pageDate:{
        list:[],
        pageNo:1,
        pageSize:5,
        total:0
      },
      //搜索过滤条件
      filter: {
        nianXian:{//工作年限
          value:"",
          list:[]
        },
        province:{//省份
          value:"",
          list:[]
        },
        city:{//市
          value:"",
          list:[]
        },
        area:{//县区
          value:"",
          list:[]
        },

      },
    };
  },
});
</script>
<style lang="scss">
/*顶部banner********************/
.sheJiShi .banner {
  width: 19.2rem;
  height: 2.67rem;
  font-weight: bold;
  font-size: 0.82rem;
  text-align: center;
  line-height: 2.67rem;
  color: #FFFFFF;
  background-color111111: aqua;
  background-image: url(../../public/img/img5_1.png);
  background-repeat: repeat; /* 平铺背景 */
  background-size: cover; /* 覆盖整个容器 */
}

.sheJiShi .search{
  margin: 0 1rem;
  margin-top: 0.58rem;
  text-align: right;
  .el-input {
    width: 3.61rem;
    min-width: 140px;
    .el-button {
      font-size1111: 0.2rem;
    }
    .el-input__inner {
      font-size: 0.2rem;
      height: 0.5rem;
      line-height: 0.5rem;
      min-height: 24px;
    }
  }
}

.sheJiShi .filterBox {
  margin: 0 1rem;
  margin-top: 0.1rem;
  .filterBar {
    border-bottom: 1px solid #E8E8E8;
    height: 1rem;
    .lable {
      float: left;
      font-size: 0.21rem;
      font-weight: 600;
      color: #333333;
      padding-top: 0.44rem;
    }
    .values {
      float: right;
      padding-top: 0.4rem;
      height: 0.6rem;
      width: 16rem;
      .value {
        cursor: pointer;
        float: left;
        font-size: 0.19rem;
        line-height: 0.22rem;
        color: #8A8A8A;
        margin-right: 0.33rem;
        padding: 0.09rem 0.09rem 0.06rem 0.09rem;
      }
      .active {
        background-color: #71BE33;
        border-radius: 0.03rem;
        color: #FFFFFF;
      }
      .selectValue {
        margin-right: 0.6rem;
        float: left;
        .el-input{
          font-size: 0.19rem;
          border-bottom: 1px solid #DCDFE6;
          padding: 0.03rem 0.09rem 0.06rem 0.09rem;
          .el-input__inner{
            padding: 0px;
            height: 0.22rem;
            border-radius: 0;
            line-height: 0.22rem;
            border: 0px solid #DCDFE6;
          }
          .el-input__suffix {
            height: 0.22rem;
            line-height: 0.22rem;
            .el-input__icon{
              height: 0.22rem;
              line-height: 0.22rem;
              font-size: 0.22rem;
              margin-top: 0.05rem
            }
          } 
        }
      }
      .selectActive {
        .el-input{
          border-bottom: 1px solid #71BE33;
        }
      }
    }
  }
}

.sheJiShi .contentBox{
  margin: 0 1rem;
  margin-top: 0.5rem;
}

.contentBox .contentItems{
  .contentItem {
    background-color1111: #e8e9e7;
    position: relative;
    margin-top: 1rem;
    .headerImg {
      width: 3.82rem;
      height: 3.96rem;
      background: #E3E3E3;
      float: left;
      background-repeat: round;
      cursor: pointer;
    }
    .userInfo {
      float: right;
      background-color111111: #8A8A8A;
      width: 12.53rem;
      height: 3.96rem;
      .name {
        font-weight: 600;
        font-size: 0.37rem;
        color: #333333;
        cursor: pointer;
      }
      .otherBox {
        width: 12.53rem;
        height: 2.283rem;
        line-height: 0.44rem;
        margin-top: 0.3rem;
        background-color111111: #798f67;
        .other{
          font-size: 0.22rem;
          color: #ACACAC;
          span {
            color: #333333;
          }
        }
      }
      .numberBox {
        background-color111111: #f66c27;
        .numberItem {
          float: left;
          width: 3.4rem;
          background-color111111: #DCDFE6;
          margin-right: 10px;
          .number {
            font-weight: 600;
            color: #E24E00;
            font-size: 0.37rem;
          }
          .title {
            color:#8E8E8E;
            font-size: 0.22rem;
          }
        }
      }
    }
    .button {
      background-color: #0ccae8;
      right: 0;
      top: 0.9rem;
      width: 2.93rem;
      position: absolute;
      .el-button {
        border-radius: 0px;
        background: #71be33;
        padding: 0px;
        width: 100%;
        height: 0.63rem;
        font-size: 0.17rem;
        display: block;
      }
    }
    .imgs {
      display: inline-block;
      white-space: nowrap;
      overflow-x:scroll;
      overflow-y: hidden;
      padding-top: 0.4rem;
      .img{
        cursor: pointer;
        display: inline-block;
        height: 3.41rem;
        margin-right: 0.4rem;
      }
    }
  }
}
</style>
