<template>
  <div class="sheJiShiInfo">
    <!--顶部图片-->
    <div class="banner">

      <div class="contentItem">
          <div class="headerImg" :style="{backgroundImage: 'url('+info.avatar+')'}"></div>
          <div class="userInfo">
            <div class="name">{{ info.name }}</div>
            <div class="otherBox">
              <div class="other" v-if="info.projectExperience!=null&&info.projectExperience!=''"><span>项目经验：</span>{{ info.projectExperience }}</div>
              <div class="other" v-if="info.prizeProject!=null&&info.prizeProject!=''"><span>所获奖项：</span>{{ info.prizeProject }}</div>
              <div class="other" v-if="info.qualifications!=null&&info.qualifications!=''"><span>行业资质：</span>{{ info.qualifications }}</div>
              <div class="other" v-if="info.style!=null&&info.style!=''"><span>擅长风格：</span>{{ info.style | replaceStr }}</div>
            </div>
            <div class="numberBox">
              <div class="numberItem">
                <div class="number">{{ info.designerCount }}个</div>
                <div class="title">案例作品</div>
              </div>
              <div class="numberItem">
                <div class="number">{{ info.operationYear }}年</div>
                <div class="title">从业年限</div>
              </div>
              <div class="numberItem">
                <div class="number">{{ info.provinceName }}</div>
                <div class="title">所在区域</div>
              </div>
            </div>
          </div>
          <div class="button">
            <el-button @click="YuYueDialogData.id=info.id;YuYueDialogData.visible=true" type="success">立即预约</el-button>
          </div>
          <div style="clear: both;"></div>
        </div>
    </div>

    <!--内容显示-->
    <div class="contentTitle">设计师案例</div>
    <div class="contentBox">
      <div class="contentBody">

        <div :class="('contentItem'+ ((index%3==1||index%3==2)?' contentItemMargin':''))" v-for="(item,index) in pageDate.list" :key="item.id">
          <div class="img" v-on:mouseup="goToAnLiInfoPage(item.id)" :style="{backgroundImage:'url('+item.coverImage+')'}"></div>
          <div class="nameBox">
            <div class="name" v-on:mouseup="goToAnLiInfoPage(item.id)" >{{ item.title }}</div>
            <div class="lookTimes">
              <div class="times">{{ item.playerNum }}</div>
              <div class="icon"></div>
            </div>
          </div>
          <div class="other">
            <!--风格-->
            <div class="style" v-if="item.style != ''">{{ item.style }}</div>
            <!--层数-->
            <div class="cengShu" v-if="item.layers>0">{{ item.layers }}层</div>
          </div>
          <div class="userInfo">
            <!--头像-->
            <div class="headerImg" v-if="item.userInfoRespVO != null" :style="{backgroundImage: 'url('+item.userInfoRespVO.avatar+')'}"></div>
            <!--昵称-->
            <div class="nickName" v-if="item.userInfoRespVO != null">{{ item.userInfoRespVO.nickname }}</div>
            <!--发布日期-->
            <div class="date">{{ item.createTime | formatDate }}</div>
          </div>
        </div>

        <div style="clear: both;"></div>
        <div class="pageInfo" style="text-align: right; padding: 0.5rem 0;">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="currentChange"
            :current-page="pageDate.pageNo"
            :page-size="pageDate.pageSize"
            :total="pageDate.total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!--预约弹窗-->
    <YuYueDialog v-model="YuYueDialogData"></YuYueDialog>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Message } from "element-ui";
import DictDataAPI from "../api/dictDataAPI";
import AnLiAPI from "../api/anLiAPI";
import SheJiShiAPI from "../api/designerAPI";

export default Vue.extend({
  name: "AnLiListView",
  components: {},
  created: function(){
    
  },
  watch: {
    '$route.query': {
      immediate: true, // 初始化时立即触发
      handler(newQuery, oldQuery) {
        console.log(newQuery);
        this.$data.info.id = newQuery.id;
        //获取详情
        this.getInfo( () => {
          //获取推荐内容
          this.getList();
        });
      }
    }
  },
  methods: {
    goToAnLiInfoPage(id){
      var url  = "";//window.location.hostname;
      url += '#/anli/info?id='+id;
      window.open(url, '_blank');
    },
    currentChange: function(current){
      this.$data.pageDate.pageNo = current;
      //更新内容
      this.getList();
    },
    getInfo(okBack){
      SheJiShiAPI.getInfoById(this.$data.info.id).then(result => {
        console.log(result);
        if(result.status != 200){
          Message.error("接口异常！");
          return;
        }
        if(result.data.code != 0){
          Message.error(result.data.msg);
          return;
        }
        this.$data.info = result.data.data;
        if(okBack){
          okBack();
        }
      }).catch(error =>{
        Message.error(error);
      });
    },
    getList: function(){
      AnLiAPI.getListByUserid(
        this.$data.info.userId,
        1,
        this.$data.pageDate.pageNo,
        this.$data.pageDate.pageSize
      ).then(result => {
        console.log(result);
        if(result.status != 200){
          Message.error("数据加载失败！");
          return;
        }
        if(result.data.code != 0){
          Message.error(result.data.msg);
          return;
        }
        this.$data.pageDate.list = result.data.data.list;
        this.$data.pageDate.total = result.data.data.total;
      }).catch(error => {
        Message.error(error);
      });
    }
  },
  data() {
    return {
      //预约Dialog
      YuYueDialogData:{
        visible:false,
        id:0,//设计师id或施工队id
        type:1//0:首页,1 设计师,2施工队,3 优选整装
      },
      info:{

      },
      pageDate:{
        list:[],
        pageNo:1,
        pageSize:12,
        total:0
      },
    };
  },
});
</script>
<style lang="scss">
.sheJiShiInfo .banner {
  width: 19.2rem;
  height: 6.86rem;
  background-image: url(../../public/img/bg7_1.png);
  background-repeat: repeat; /* 平铺背景 */
  background-size: cover; /* 覆盖整个容器 */
  .contentItem {
    background-color1111: #e8e9e7;
    position: relative;
    margin: 0 1rem;
    padding-top: 1.45rem;
    .headerImg {
      width: 3.82rem;
      height: 3.96rem;
      background: #E3E3E3;
      float: left;
      background-repeat: round;
    }
    .userInfo {
      float: right;
      background-color111111: #8A8A8A;
      width: 12.53rem;
      height: 3.96rem;
      .name {
        font-weight: 600;
        font-size: 0.37rem;
        color: #ffffff;
      }
      .otherBox {
        width: 12.53rem;
        height: 2.283rem;
        line-height: 0.44rem;
        margin-top: 0.3rem;
        background-color111111: #798f67;
        .other{
          font-size: 0.22rem;
          color: #ffffff;
          span {
            color: #ffffff;
          }
        }
      }
      .numberBox {
        background-color111111: #f66c27;
        .numberItem {
          float: left;
          width: 3.4rem;
          background-color111111: #DCDFE6;
          margin-right: 10px;
          .number {
            font-weight: 600;
            color: #ffffff;
            font-size: 0.37rem;
          }
          .title {
            color:#ffffff;
            font-size: 0.22rem;
          }
        }
      }
    }
    .button {
      background-color: #0ccae8;
      right: 0;
      top: 2.2rem;
      width: 2.93rem;
      position: absolute;
      .el-button {
        border-radius: 0px;
        background: #71be33;
        padding: 0px;
        width: 100%;
        height: 0.63rem;
        font-size: 0.17rem;
        display: block;
      }
    }
    .imgs {
      display: inline-block;
      white-space: nowrap;
      overflow-x:scroll;
      overflow-y: hidden;
      padding-top: 0.4rem;
      .img{
        cursor: pointer;
        display: inline-block;
        height: 3.41rem;
        margin-right: 0.4rem;
      }
    }
  }
}

.sheJiShiInfo .contentTitle{
  margin: 0 1rem;
  font-weight: 600;
  font-size: 0.39rem;
  color: #333333;
  margin-top: 0.58rem;
}

.sheJiShiInfo .contentBox{
  margin-top: 0.1rem;
  background-color: #F8F8F8;
  margin-bottom1111: 0.78rem;
  .contentBody{
    margin: 0 1rem;
    .contentItemMargin {
      margin-left: 0.395rem;
    }
    .contentItem {
      float: left;
      width: 5.47rem;
      height: 5.6rem;
      background: #FFFFFF;
      border-radius: 10px 10px 10px 10px;
      margin-top: 0.395rem;
      .img {
        cursor: pointer;
        width: 5.47rem;
        height: 3.48rem;
        background: #E8E8E8;
        border-radius: 10px 10px 0px 0px;
        background-repeat: round;
      }
      .nameBox{
        line-height: 0.32rem;
        padding-right:0.3rem;
        padding-left:0.3rem;
        padding-top: 0.3rem;
        background-color11111: #287DF1;
        height: 0.3rem;
        overflow: hidden;
        .name {
          cursor: pointer;
          float: left;
          font-weight: 600;
          font-size: 0.23rem;
          color: #333333;
        }
        .lookTimes {
          float: right;
          .icon {
            float: right;
            background-image: url(../../public/img/icon5_1.png);
            width: 0.22rem;
            height: 0.14rem;
            margin-top: 0.08rem;
            margin-right: 0.05rem;
            background-repeat: repeat; /* 平铺背景 */
            background-size: cover; /* 覆盖整个容器 */
          }
          .times {
            float: right;
            font-weight: 400;
            font-size: 0.16rem;
            color: #9B9B9B;
          }
        }
      }
      .other {
        padding-left: 0.3rem;
        padding-top: 0.1rem;
        background-color111: #71BE33;
        height: 0.3rem;
        overflow11: hidden;
        div {
          float: left;
          font-size: 0.18rem;
          color: #989898;
          padding-right: 0.3rem;
        }
      }
      .userInfo {
        padding-right: 0.3rem;
        padding-left: 0.3rem;
        padding-top: 0.2rem;
        .headerImg {
          float: left;
          width: 0.68rem;
          height: 0.68rem;
          background-repeat: repeat; /* 平铺背景 */
          background-size: cover; /* 覆盖整个容器 */
          border-radius: 0.34rem 0.34rem 0.34rem 0.34rem;
        }
        .nickName {
          float: left;
          font-size: 0.21rem;
          color: #555555;
          line-height: 0.68rem;
          padding-left: 0.1rem;
        }
        .date {
          font-size: 0.18rem;
          color: #9B9B9B;
          float: right;
          line-height: 0.68rem;
        }
      }
    }
    .pageInfo {
      .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: #71BE33;
      }
      .el-pagination.is-background .el-pager li:not(.active):hover {
        color: #71BE33;
      }
    }
  }
}

</style>
