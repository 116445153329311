import axios from "axios"

const list = function(pageNo, pageSize, style, operationYear, province, city, area, searchContent){
    var url = '/app-api/business/designer/page?pageNo='+pageNo+"&pageSize="+pageSize;
    if(style != null && style != ''){
        url += "&style="+encodeURI(style);
    }
    if(operationYear != null && operationYear != ''){
        url += "&operationYear="+encodeURI(operationYear);
    }
    if(province != null && province != ''){
        url += "&province="+encodeURI(province);
    }
    if(city != null && city != ''){
        url += "&city="+encodeURI(city);
    }
    if(area != null && area != ''){
        url += "&area="+encodeURI(area);
    }
    if(searchContent != null && searchContent != ''){
        url += "&searchContent="+encodeURI(searchContent);
    }
    return axios.get(url);
}

const getInfoById = function(id){
    return axios.get('/app-api/business/designer/get?id='+id);
}
/**设计团队 */
export default {
    list,getInfoById
}