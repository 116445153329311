import axios from "axios"

const getHomeList = function(){
    return axios.get('/app-api/business/home/search/page?secrecy=0&pageNo=1&pageSize=10');
}

const getAnLiInfo = function(id){
    return axios.get('/app-api/business/user/dynamics/get?id='+id);
}

const searchList = function(pageNo, pageSize, keyword, type, fengGe, jinShen, mianKuan, chengShu, zaoJia){
    var url = "/app-api/business/home/search/page?secrecy=0&pageSize="+pageSize+"&pageNo="+pageNo;
    if(keyword != ""){
        url += "&searchContent="+encodeURI(keyword);
    }
    if(fengGe != ""){
        url += "&style="+encodeURI(fengGe);
    }
    if(jinShen != ""){
        url += "&buildingLong="+encodeURI(jinShen);
    }
    if(mianKuan != ""){
        url += "&buildingWidth="+encodeURI(mianKuan);
    }
    if(chengShu != ""){
        url += "&layers="+encodeURI(chengShu);
    }
    if(zaoJia != ""){
        url += "&budget="+encodeURI(zaoJia);
    }
    //'全部案例', '1整包方案','2精品案例','3局部改造','4新建案例'
    if(type > 0){
        if(type == 2){
            url += "&recommend=1";
        }else if(type == 3){
            url += "&contentType=2";
        }else if(type == 4){
            url += "&contentType=1";
        }
    }
    return axios.get(url);
}

const getTuiJianList = function(id, style, pageNo, pageSize){
    var url = "/app-api/business/home/correlation/page?id="+id+"&pageSize="+pageSize+"&pageNo="+pageNo;
    if(style != ""){
        url += "&style="+encodeURI(style);
    }
    return axios.get(url);
}

/**
 * 分页获取动态
 * @param {*} userid 用户id
 * @param {*} dynamicsType 0普通用户，1设计师，2施工队
 * @param {*} pageNo 
 * @param {*} pageSize 
 * @returns 
 */
const getListByUserid = function(userid, dynamicsType, pageNo, pageSize){
    var url = "/app-api/business/user/dynamics/page?userId="+userid+"&pageSize="+pageSize+"&pageNo="+pageNo;
    if(dynamicsType != ""){
        url += "&dynamicsType="+encodeURI(dynamicsType);
    }
    return axios.get(url);
}

/**实景案例 */
export default {
    getHomeList,
    searchList,
    getAnLiInfo,
    getTuiJianList,
    getListByUserid
}