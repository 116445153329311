import axios from "axios"

const getAll = function(){
    return axios.get('/app-api/system/dict-data/simple-list');
}

const getMapByTypes = function(types, okCall, errCall){
    getAll().then(result => {
        if(result.status != 200){
            errCall("请求失败，状态码："+result.status);
            return;
        }else{
            if(result.data.code != 0){
                errCall(result.data.msg);
                return;
            }else{
                okCall(filterResult(result.data.data, types));
                return;
            }
        }
    }).catch(error => {
        errCall(error);
    });
}

const filterResult = function(list, types){
    var backMap = {};
    for (let i = 0; i < types.length; i++) {
        const type = types[i];
        backMap[type] = [];
    }
    for (let i = 0; i < list.length; i++) {
        const dictObj = list[i];
        if(dictObj.dictType in backMap){
            backMap[dictObj.dictType].push(dictObj);
        }
    }
    return backMap;
}

/**字典 */
export default {
    getAll,
    getMapByTypes
}