import axios from "axios"

const list = function(pageNo, pageSize, operationYear, province, city, area, searchContent){
    var url = '/app-api/business/company/page?pageNo='+pageNo+"&pageSize="+pageSize;
    if(operationYear != null && operationYear != ''){
        url += "&operationYear="+encodeURI(operationYear);
    }
    if(province != null && province != ''){
        url += "&province="+encodeURI(province);
    }
    if(city != null && city != ''){
        url += "&city="+encodeURI(city);
    }
    if(area != null && area != ''){
        url += "&area="+encodeURI(area);
    }
    if(searchContent != null && searchContent != ''){
        url += "&searchContent="+encodeURI(searchContent);
    }
    return axios.get(url);
}
const getInfoById = function(id){
    var url = '/app-api/business/company/get?id='+id;
    return axios.get(url);
}
/**
 * 获取施工队资质信息
 * @returns 
 */
const getLicenseByCompanyId = function(companyId, type){
    var url = '/app-api/business/company/license/target/get?companyId='+companyId+"&type="+type;
    return axios.get(url);
}

/**施工团队 */
export default {
    list,getInfoById,getLicenseByCompanyId
}