<template>
  <div class="shiGongDuiInfo">
    <!--顶部图片-->
    <div class="banner">
      <el-carousel height="6.86rem">
        <el-carousel-item v-for="(img,index) in banner.imgs" :key="index">
          <div class="imgBox">
            <div class="imgBg" :style="{backgroundImage: 'url('+img+')'}"></div>
            <div class="img" :style="{backgroundImage: 'url('+img+')'}"></div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!--施工队信息-->
    <div class="infoBox">
      <div class="headerImg" :style="{backgroundImage: 'url('+info.logoImage+')'}"></div>
      <div class="userInfo">
        <div class="name">{{ info.companyName }}</div>
        <div class="otherBox">
          <div class="other" v-if="info.tradeDate!=null&&info.tradeDate!=''"><span>营业时间：</span>{{ info.tradeDate }}</div>
          <div class="other" v-if="info.businessContent!=null&&info.businessContent!=''"><span>主营业务：</span>{{ info.businessContent }}</div>
          <div class="other" v-if="info.scale!=null&&info.scale!=''"><span>规模：</span>{{ info.scale }}年老店</div>
          <div class="other" v-if="info.style!=null&&info.style!=''"><span>擅长风格：</span>{{ info.style | replaceStr }}</div>
        </div>
        <div class="numberBox">
          <div class="numberItem">
            <div class="number">{{ info.companyCount }}个</div>
            <div class="title">案例作品</div>
          </div>
          <div class="numberItem">
            <div class="number">{{ info.operationYear }}年</div>
            <div class="title">经营年限</div>
          </div>
          <div class="numberItem" v-if="info.provinceName!=null&&info.provinceName!=''">
            <div class="number">{{ info.provinceName }}</div>
            <div class="title">所在区域</div>
          </div>
        </div>
      </div>
      <div class="button">
        <el-button @click="YuYueDialogData.id=info.id;YuYueDialogData.visible=true" type="success">立即预约</el-button>
      </div>
    </div>
    <!--施工队资质信息-->
    <div class="ziZhiTtitle" v-if="licenseInfo.id">公司资质</div>
    <div class="ziZhiBox" v-if="licenseInfo.id">
      <div class="boxLeft">
        <div class="bg"></div>
        <div class="content" :style="{backgroundImage: 'url('+licenseInfo.businessImage+')'}"></div>
      </div>
      <div class="boxRight">
        <div class="bg"></div>
        <div class="content">
          <div class="item"><span>公司名称：</span>{{ licenseInfo.companyName }}</div>
          <div class="item"><span>信用代码：</span>{{ licenseInfo.businessLicence }}</div>
          <div class="item"><span>法人姓名：</span>{{ licenseInfo.legalName }}</div>
        </div>
      </div>
    </div>
    <!--公司案例-->
    <div class="ziZhiTtitle" v-if="pageDate.list.length > 0">公司案例</div>
    <div class="anLiBox" v-if="pageDate.list.length > 0">
      <div v-on:mouseup="goToAnLiPage(item.id)" :class="'item'+(index%3==0?'':' marginLeft') +(index<=2?'':' marginTop')" v-for="(item,index) in pageDate.list" :key="index" :style="{backgroundImage: 'url('+item.coverImage+')'}">

      </div>
      <div style="clear: both;"></div>
    </div>
    <div class="pageInfo" v-if="pageDate.list.length > 0" style="text-align: right;">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="currentChange"
        :current-page="pageDate.pageNo"
        :page-size="pageDate.pageSize"
        :total="pageDate.total">
      </el-pagination>
    </div>
    <!--预约弹窗-->
    <YuYueDialog v-model="YuYueDialogData"></YuYueDialog>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Message } from "element-ui";
import CompanyAPI from "../api/companyAPI";
import AnLiAPI from "../api/anLiAPI";

export default Vue.extend({
  name: "AnLiInfoView",
  components: {},
  created: function(){
    
  },
  watch: {
    '$route.query': {
      immediate: true, // 初始化时立即触发
      handler(newQuery, oldQuery) {
        console.log(newQuery);
        this.$data.info.id = newQuery.id;
        //公司资质
        this.$data.licenseInfo.id = null;
        this.getLicenseInfo();
        //获取详情
        this.getInfo( () => {
          this.getAnLiList();
        });
      }
    }
  },
  methods: {
    currentChange: function(current){
      this.$data.pageDate.pageNo = current;
      //更新内容
      this.getAnLiList();
    },
    goToAnLiPage(id){
      this.$router.push('/anli/info?id='+id);
      window.scrollTo({
        top: 0,
        left: 0,
        //behavior: 'smooth' // 平滑滚动
      });
    },
    //获取详情
    getInfo: function(okBack){
      CompanyAPI.getInfoById(this.$data.info.id).then(result => {
        console.log(result);
        if(result.status != 200){
          Message.error("接口异常！");
          return;
        }
        if(result.data.code != 0){
          Message.error(result.data.msg);
          return;
        }
        this.$data.info = result.data.data;
        this.$data.banner.imgs = [];
        //轮播图
        if(this.$data.info.backgroundImage){
          this.$data.banner.imgs = this.$data.info.backgroundImage.split(',');
        }
        //处理完成回调
        if(okBack){
          okBack();
        }
      }).catch(error => {
        Message.error(error);
      });
    },
    //获取公司资质详情信息
    getLicenseInfo: function(){
      CompanyAPI.getLicenseByCompanyId(this.$data.info.id, 0).then(result => {
        console.log(result);
        if(result.status != 200){
          Message.error("接口异常！");
          return;
        }
        if(result.data.code != 0){
          Message.error(result.data.msg);
          return;
        }
        this.$data.licenseInfo = result.data.data;
      }).catch(error => {
        Message.error(error);
      });
    },
    getAnLiList(){
      AnLiAPI.getListByUserid(this.$data.info.userId,2, this.$data.pageDate.pageNo, this.$data.pageDate.pageSize).then(result => {
        console.log(result);
        if(result.status != 200){
          Message.error("接口异常！");
          return;
        }
        if(result.data.code != 0){
          Message.error(result.data.msg);
          return;
        }
        this.$data.pageDate.list = result.data.data.list;
        this.$data.pageDate.total = result.data.data.total;
      }).catch(error => {
        Message.error(error);
      });
    }
  },
  data() {
    return {
      info:{},
      licenseInfo:{},
      banner:{
        imgs:[]
      },
      pageDate:{
        list:[],
        pageNo:1,
        pageSize:9,
        total:0
      },
      //预约Dialog
      YuYueDialogData:{
        visible:false,
        id:0,//设计师id或施工队id
        type:2//0:首页,1 设计师,2施工队,3 优选整装
      }
    }
  },
});
</script>
<style lang="scss">
.shiGongDuiInfo .banner{
  width: 19.2rem;
  height: 6.86rem;
  .imgBox {
    width: 19.2rem;
    height: 6.86rem;
    background-color111: #71BE33;
    position: relative;
    .imgBg {
      width: 19.2rem;
      height: 6.86rem;
      background-repeat: repeat; /* 平铺背景 */
      background-size: cover; /* 覆盖整个容器 */
      position: absolute;
      filter: blur(5px); /* 设置模糊效果 */
    }
    .img {
      width: 19.2rem;
      height: 6.86rem;
      position: absolute;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

.shiGongDuiInfo  .infoBox {
  background-color1111: #e8e9e7;
  position: relative;
  margin: 0.78rem 1rem 0 1rem;
  height: 3.96rem;
  .headerImg {
    width: 3.82rem;
    height: 3.96rem;
    background: #E3E3E3;
    float: left;
    background-repeat: round;
  }
  .userInfo {
    float: right;
    background-color111111: #8A8A8A;
    width: 12.53rem;
    height: 3.96rem;
    .name {
      font-weight: 600;
      font-size: 0.37rem;
      color: #333333;
    }
    .otherBox {
      width: 12.53rem;
      height: 2.283rem;
      line-height: 0.44rem;
      margin-top: 0.3rem;
      background-color111111: #798f67;
      .other{
        font-size: 0.22rem;
        color: #ACACAC;
        span {
          color: #333333;
        }
      }
    }
    .numberBox {
      background-color111111: #f66c27;
      .numberItem {
        float: left;
        width: 3.4rem;
        background-color111111: #DCDFE6;
        margin-right: 10px;
        .number {
          font-weight: 600;
          color: #E24E00;
          font-size: 0.37rem;
        }
        .title {
          color:#8E8E8E;
          font-size: 0.22rem;
        }
      }
    }
  }
  .button {
    background-color: #0ccae8;
    right: 0;
    top: 0.9rem;
    width: 2.93rem;
    position: absolute;
    .el-button {
      border-radius: 0px;
      background: #71be33;
      padding: 0px;
      width: 100%;
      height: 0.63rem;
      font-size: 0.17rem;
      display: block;
    }
  }
  .imgs {
    display: inline-block;
    white-space: nowrap;
    overflow-x:scroll;
    overflow-y: hidden;
    padding-top: 0.4rem;
    .img{
      cursor: pointer;
      display: inline-block;
      height: 3.41rem;
      margin-right: 0.4rem;
    }
  }
}

.shiGongDuiInfo  .ziZhiTtitle {
  font-weight: 600;
  font-size: 0.39rem;
  color: #333333;
  margin: 0.78rem 1rem 0.3rem 1rem;
  margin-top: 0.78rem;
}
.shiGongDuiInfo  .ziZhiBox{
  width: 19.2rem;
  height: 6.16rem;
  background-image: url(../../public/img/bg7_1.png);
  background-repeat: round;
  .boxLeft{
    float: left;
    width: 6.7rem;
    height: 5.03rem;
    margin-left: 2.64rem;
    margin-top: 0.565rem;
    position: relative;
    .bg {
      position: absolute;
      width: 6.7rem;
      height: 5.03rem;
      background-color: #000000;
      opacity: 0.5;
    }
    .content {
      position: absolute;
      width: 6.7rem;
      height: 5.03rem;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .boxRight{
    float: right;
    width: 6.7rem;
    height: 5.03rem;
    margin-right: 2.64rem;
    margin-top: 0.565rem;
    position: relative;
    .bg {
      position: absolute;
      width: 6.7rem;
      height: 5.03rem;
      background-color: #000000;
      opacity: 0.5;
    }
    .content {
      position: absolute;
      width: 6.7rem;
      height: 5.03rem;
      margin-top: 0.5rem;
      .item{
        width: 4.4rem;
        border-bottom: 1px solid #727070;
        font-size: 0.22rem;
        color: #FFFFFF;
        margin-left: 1.15rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
      }
    }
  }
}

.shiGongDuiInfo  .anLiBox{
  margin: 0.3rem 1rem 0.3rem 1rem;
  .item{
    float: left;
    width: 5.29rem;
    height: 3.85rem;
    background-repeat: round;
    border-radius: 0.1rem;
    cursor: pointer;
  }
  .marginLeft{
    margin-left: 0.665rem;
  }
  .marginTop{
    margin-top: 0.665rem;
  }
}

.shiGongDuiInfo  .pageInfo{
  margin: 0 1rem;
}
</style>
