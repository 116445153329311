<template>
  <div class="youXuan">
    <div class="banner"></div>
    <!--整包服务-->
    <div class="zbnrBox">
      <div class="left">
        <div class="zhengBao1">整包</div>
        <div class="zhengBao2">服务内容</div>
      </div>
      <div class="right">
        <div class="item">
          <div class="text">
            <div class="text1">设计费全免</div>
            <div class="text2">全程免费为您量房设计</div>
          </div>
          <div class="icon icon1"><img src="../../public/img/icon2_1.png"/></div>
        </div>
        <div class="item floatRight">
          <div class="text">
            <div class="text1">95%还原设计</div>
            <div class="text2">按照图纸施工，最终效果还原度可达95%</div>
          </div>
          <div class="icon icon2"><img src="../../public/img/icon2_2.png"/></div>
        </div>
        <div class="item marginTop">
          <div class="text">
            <div class="text1">施工提速30%</div>
            <div class="text2">优化各个流程节点，整体施工时间提速30%</div>
          </div>
          <div class="icon icon3"><img src="../../public/img/icon2_3.png"/></div>
        </div>
        <div class="item floatRight marginTop">
          <div class="text">
            <div class="text1">二年质保</div>
            <div class="text2">项目交付后仍有两年质保，售后无忧</div>
          </div>
          <div class="icon icon4"><img src="../../public/img/icon2_4.png"/></div>
        </div>
        <div class="item width100 marginTop">
          <div class="text">
            <div class="text1">损耗全包节约10%成本</div>
            <div class="text2">损耗由我们承担，相当于您节约10&的成本</div>
          </div>
          <div class="icon icon5"><img src="../../public/img/icon2_5.png"/></div>
        </div>
      </div>
    </div>

    <!-- 精装级配置 -->
    <div class="bigTitle">
      <div class="title">精装级配置</div>
      <div class="subTitle">用心做好每一个细节，交给您一个完整的家</div>
    </div>
    <div class="peiZhi">
      <div class="menus">
        <div :class="'menu'+(jingZhuangPeiZhi.index == 0?' menuActiv':'')" @click="jingZhuangClick(0)">客厅</div>
        <div :class="'menu'+(jingZhuangPeiZhi.index == 1?' menuActiv':'')"  @click="jingZhuangClick(1)">卧室</div>
        <div :class="'menu'+(jingZhuangPeiZhi.index == 2?' menuActiv':'')" @click="jingZhuangClick(2)">厨房</div>
        <div :class="'menu'+(jingZhuangPeiZhi.index == 3?' menuActiv':'')"  @click="jingZhuangClick(3)">卫生间</div>
      </div>
      <div class="menuImg" :style="{backgroundImage:'URL('+jingZhuangPeiZhi.imgs[jingZhuangPeiZhi.index]+')'}"></div>
      <div style="clear: both;"></div>
    </div>
    <!--立即预约-->
    <div class="bigTitle">
      <div class="title">设计费全免</div>
      <div class="subTitle">全程免费为您量房设计</div>
    </div>
    <div class="yuYueBox">
      <div class="left">
        <div class="text1">全自动AI设计</div>
        <div class="text2">更方便 更快捷<br>不需要您掏一分钱</div>
      </div>
      <div class="right">
        <el-form
          :model="yuYueForm"
          :rules="yuYueRules"
          ref="yuYueForm"
          label-width="0px"
        >
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item prop="mianJi">
                <el-input
                  tabindex="0"
                  v-model="yuYueForm.mianJi"
                  placeholder="请输入您的房屋面积"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="xingMing">
                <el-input
                  v-model="yuYueForm.xingMing"
                  placeholder="请输入您的姓名"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item prop="dianHua">
            <el-input
              v-model="yuYueForm.dianHua"
              placeholder="请输入您的联系电话"
            ></el-input>
          </el-form-item>
          <el-button :disabled="yuYueForm.submiting" @click="submitForm('yuYueForm')" type="success"
            >立即预约</el-button
          >
        </el-form>
      </div>
    </div>
    <!--95%还原设计-->
    <div class="bigTitle">
      <div class="title">95%还原设计</div>
      <div class="subTitle">按照图纸施工，最终效果还原度可达95%</div>
    </div>
    <div class="huanYSJ">
      <div class="item" style="margin-left: 0rem">
        <div class="img" style="background-image: url(/img/img9_2.png);"></div>
        <div class="content">
          <div class="title">风格定向 产品预选</div>
          <div class="text">和客户完成整体风格色系定向完成产品框架方案</div>
        </div>
      </div>
      <div class="item">
        <div class="content">
          <div class="title">设计搭配 产品建模</div>
          <div class="text">设计师结合客户意见进行产品建模</div>
        </div>
        <div class="img" style="background-image: url(/img/img9_3.png);"></div>
      </div>
      <div class="item">
        <div class="img" style="background-image: url(/img/img9_4.png);"></div>
        <div class="content">
          <div class="title">有的放矢 高清渲染</div>
          <div class="text">全部使用真实材料进行效果图渲染</div>
        </div>
      </div>
      <div class="item">
        <div class="content">
          <div class="title">95%还原设计施工</div>
          <div class="text">客户确定效果图，施工队按照效果图施工</div>
        </div>
        <div class="img" style="background-image: url(/img/img9_5.png);"></div>
      </div>

    </div>
    <!--施工提速30%-->
    <div class="bigTitle">
      <div class="title">施工提速30%</div>
      <div class="subTitle">优化各个流程节点，整体施工时间提速30%</div>
    </div>
    <div class="tiSu">
      <div class="item">
        <div class="icon"><img src="/img/img9_7.png"/></div>
        <div class="title">整体化交付流程</div>
        <div class="subTitle">从施工到材料拒绝不确定</div>
      </div>
      <div class="item">
        <div class="icon"><img src="/img/img9_8.png"/></div>
        <div class="title">专业化订单中心</div>
        <div class="subTitle">全程跟进，货品无忧</div>
      </div>
      <div class="item">
        <div class="icon"><img src="/img/img9_9.png"/></div>
        <div class="title">项目经理全责制</div>
        <div class="subTitle">全程贯穿服务无缝衔接</div>
      </div>
    </div>
    <!-- 损耗全包节约10%成本 -->
    <div class="bigTitle">
      <div class="title">损耗全包节约10%成</div>
      <div class="subTitle">损耗由我们承担，相当于您节约10%的成本</div>
    </div>
    <div class="jieYue">
      <div class="item">
        <div class="img" style="background-image: url(/img/img9_10.png);">
          <div class="title">瓷砖</div>
        </div>
        <div class="text">损耗无论多少，客户无需付款</div>
      </div>
      <div class="item">
        <div class="img" style="background-image: url(/img/img9_11.png);">
          <div class="title">地板</div>
        </div>
        <div class="text">损耗无论多少，客户无需付款</div>
      </div>
      <div class="item" style="margin-right: 0">
        <div class="img" style="background-image: url(/img/img9_12.png);">
          <div class="title">运输</div>
        </div>
        <div class="text">负责所有退补货费用与客户无关</div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <!-- 真实案例 -->
    <div class="bigTitle" v-if="anLiData.list.length >= 3">
      <div class="title">真实案例</div>
    </div>
    <div class="anli" v-if="anLiData.list.length >= 3">
      <div class="anli1" v-on:mouseup="goToAnLiPage(anLiData.list[0].id)" :style="{backgroundImage:'url('+anLiData.list[0]['coverImage']+')'}"></div>
      <div class="anli2" v-on:mouseup="goToAnLiPage(anLiData.list[1].id)" :style="{backgroundImage:'url('+anLiData.list[1]['coverImage']+')'}"></div>
      <div class="anli3" v-on:mouseup="goToAnLiPage(anLiData.list[2].id)" :style="{backgroundImage:'url('+anLiData.list[2]['coverImage']+')'}"></div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import DesignerAPI from "../api/designerAPI";
import CompanyAPI from "../api/companyAPI";
import ReservationLogAPI from "../api/reservationLogAPI";
import AnLiAPI from "../api/anLiAPI";
import { Message } from "element-ui";

export default Vue.extend({
  name: "YouXuanView",
  components: {},
  created: function(){
    //实景案例
    AnLiAPI.getHomeList().then(result =>{
      console.log(result);
      if(result.status == 200 && result.data.code == 0){
        this.$data.anLiData.list = result.data.data.list;
      }
    }).catch(error => {

    });
  },
  methods: {
    goToAnLiPage(id){
      this.$router.push('/anli/info?id='+id);
      window.scrollTo({
        top: 0,
        left: 0
      });
    },
    jingZhuangClick(index){
      this.$data.jingZhuangPeiZhi.index = index;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$data.yuYueForm.submiting = true;
          ReservationLogAPI.create(
            0, 3, 
            this.$data.yuYueForm.mianJi, 
            this.$data.yuYueForm.xingMing, 
            this.$data.yuYueForm.dianHua)
          .then(result =>{
            console.log(result);
            setTimeout(() => {
              this.$data.yuYueForm.submiting = false;
            }, 1000);
            if(result.status == 200 && result.data.code == 0){
              Message.success("预约成功！");
            }else{
              Message.error(result.data.msg);
            }
          });
        } else {
          console.log("error submit!!");
          setTimeout(() => {
              this.$data.yuYueForm.submiting = false;
            }, 1000);
          return false;
        }
      });
    }
  },
  data() {
    return {
      jingZhuangPeiZhi:{
        index:0,
        imgs:["/img/img9_13_1.png","/img/img9_13_2.png","/img/img9_13_3.png","/img/img9_13_4.png"]
      },
      //设计案例
      anLiData:{
        list:[]
      },
      //立即预约
      yuYueForm: {
        mianJi: null,
        xingMing: null,
        dianHua: null,
        submiting:false,
      },
      yuYueRules: {
        mianJi: [
          { required: true, message: "请输入您的房屋面积", trigger: "blur" },
        ],
        xingMing: [
          { required: true, message: "请输入您的姓名", trigger: "blur" },
        ],
        dianHua: [
          { required: true, message: "请输入您的联系电话", trigger: "blur" },
        ],
      },
    };
  },
});
</script>
<style lang="scss">
/*顶部banner********************/
.youXuan .banner {
  width: 19.2rem;
  height: 9.25rem;
  background-color1111111: aqua;
  background-image: url(../../public/img/homeBanner1.png);
  background-repeat: round;
}
/*整包服务内容**********开始**********/
.youXuan .zbnrBox {
  height: 3.68rem;
  margin: 0 1rem;
  margin-top: 0.78rem;
  .left {
    float: left;
    background-image: url(../../public/img/bg2_1.png);
    background-repeat: round;
    width: 3.9rem;
    height: 3.68rem;
    font-weight: 500;
    .zhengBao1 {
      color: #95DE5A;
      font-size: 0.55rem;
      margin-left: 1rem;
      padding-top: 1.1rem;
    }
    .zhengBao2 {
      color: #ffffff;
      font-size: 0.46rem;
      margin-left: 1rem;
    }
  }
  .right {
    float: right;
    background-color11111: #1c9a0f;
    width: 12rem;
    .item {
      background-color: #EFEFEF;
      width: 5.5rem;
      height: 1.1rem;
      float: left;
      .text {
        float: left;
        width: 4rem;
        margin-left: 0.4rem;
        margin-top: 0.21rem;
        .text1 {
          font-size: 0.28rem;
          color: #333333;
        }
        .text2 {
          font-size: 0.19rem;
          color: #7B7B7B;
        }
      }
      .icon {
        float: right;
        margin-right: 0.2rem;
        margin-top: 0.24rem;
      }
      .icon1 {
        img {
          width: 0.61rem;
          height: 0.61rem;
        }
      }
      .icon2 {
        img {
          width: 0.78rem;
          height: 0.61rem;
        }
      }
      .icon3 {
        img {
          width: 0.62rem;
          height: 0.61rem;
        }
      }
      .icon4{
        img {
          width: 0.58rem;
          height: 0.74rem;
        }
      }
      .icon5{
        img {
          width: 0.55rem;
          height: 0.55rem;
        }
      }
    }
    .marginTop {
      margin-top: 0.19rem;
    }
    .floatRight {
      float: right;
    }
    .width100 {
      width: 100%;
    }
  }
}

/*精装级配置*/
.youXuan .peiZhi{
  margin-top: 0.2rem;
  .menus {
    margin: 0 1rem;
    height: 0.63rem;
    .menu {
      float: left;
      font-weight: 400;
      font-size: 0.4rem;
      color: #333333;
      margin: 0 1.7rem;
    }
    .menuActiv {
      font-weight: 600;
      color: #1C9A0F;
      border-bottom: 5px solid #1c9a0f;
    }
  }
  .menuImg {
    height: 9.25rem;
      background-repeat: repeat; /* 平铺背景 */
      background-size: cover; /* 覆盖整个容器 */
  }
}

.youXuan .bigTitle {
  margin: 0 1rem;
  margin-top: 0.78rem;
  .title {
    font-weight: 600;
    font-size: 0.5rem;
    color: #333333;
    text-align: center;
  }
  .subTitle {
    font-weight: 400;
    font-size: 0.28rem;
    color: #7E7E7E;
    text-align: center;
    margin-top: 0.1rem;
  }
}
/*立即预约********************/
.youXuan .yuYueBox {
  height: 6.58rem;
  background-image: url(../../public/img/img9_1.png);
  background-repeat: round;
  margin: 0 1rem;
  margin-top: 0.2rem;
  .left {
    float: left;
    padding-left: 1rem;
    padding-top: 1.8rem;
    .text1{
      font-family: Source Han Serif CN, Source Han Serif CN;
      font-size: 0.6rem;
      font-weight: 800;
      color: #FFFFFF;
    }
    .text2{
      font-size: 0.4rem;
      font-weight: 500;
      color: #FFFFFF;
    }
  }
  .right {
    float: right;
    width: 8.25rem;
    padding-right: 1rem;
    padding-top: 1.8rem;
    .rightInput1 {
      width: 4rem;
      float: left;
    }
    .el-form-item__content {
      line-height: 0.68rem;
    }
    .el-form-item {
      margin-bottom: 0.32rem;
    }
    .el-form-item__error {
      padding-top: 0.04rem;
      font-size: 0.15rem;
    }
    .el-input__inner {
      height: 0.63rem;
      border-radius: 0px;
      font-size: 0.17rem;
    }
    .el-button {
      border-radius: 0px;
      background: #71be33;
      padding: 0px;
      width: 100%;
      height: 0.63rem;
      font-size: 0.17rem;
      display: block;
    }
  }
}
/*95%还原设计*/
.youXuan .huanYSJ {
  margin: 0 1rem;
  margin-top: 0.2rem;
  height: 6.58rem;
  .item {
    float: left;
    width: 3.54rem;
    height: 6.58rem;
    background-color: #EFEFEF;
    margin-left: 1.0133rem;
    .img {
      width: 3.54rem;
      height: 4.66rem;
      background: #5E6F51;
      background-repeat: round;
    }
    .content {
      height: 1.92rem;
      margin: 0 0.3rem;
      .title {
        font-weight: 600;
        font-size: 0.35rem;
        color: #333333;
        padding-top: 0.3rem;
      }
      .text {
        font-weight: 400;
        font-size: 0.22rem;
        color: #797979;
        margin-top: 0.1rem;
      }
    }
  }
}
/*施工提速30%*/
.youXuan .tiSu{
  height: 4.04rem;
  background-image: url(../../public/img/img9_6.png);
  background-repeat: round;
  margin: 0 1rem;
  margin-top: 0.2rem;
  .item {
    float: left;
    width: 5.733rem;
    text-align: center;
    .icon {
      margin-top: 0.9rem;
      img {
        height: 0.84rem;
      }
    }
    .title {
      font-weight: 600;
      font-size: 0.35rem;
      color: #FFFFFF;
      padding-top: 0.4rem;
    }
    .subTitle {
      font-weight: 400;
      font-size: 0.22rem;
      color: #FFFFFF;
      padding-top: 0.2rem;
    }
  }
}
/*损耗全包节约10%成本 */
.youXuan .jieYue {
  margin: 0 1rem;
  margin-top: 0.2rem;
  .item {
    float: left;
    width: 4.7rem;
    margin-right: 1.55rem;
    .img {
      width: 4.7rem;
      height: 4.7rem;
      background-repeat: round;
      .title {
        font-weight: 600;
        font-size: 0.8rem;
        color: #FFFFFF;
        text-align: center;
        line-height: 4.7rem;
      }
    }
    .text {
      font-weight: 400;
      font-size: 0.3rem;
      color: #333333;
      padding-top: 0.3rem;
    }
  }
}

.youXuan .anli {
  margin: 0 1rem;
  margin-top: 0.2rem;
  height: 7.6rem;
  .anli1{
    float: left;
    height: 7.6rem;
    width: 11rem;
    background-color: #ececec;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;

  }
  .anli2{
    float: right;
    height: 3.45rem;
    width: 5.5rem;
    background-color: #ececec;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .anli3{
    margin-top: 0.7rem;
    float: right;
    height: 3.45rem;
    width: 5.5rem;
    background-color: #ececec;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
  }
}
</style>
