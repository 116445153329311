<template>
  <el-dialog
    class="yuYueDialog"
    title="填写预约信息"
    :visible.sync="value.visible"
    width="30%">
    <el-form
      :model="yuYueForm"
      :rules="yuYueRules"
      ref="yuYueForm"
      label-width="0px"
    >
    <el-form-item prop="mianJi">
      <el-input
        tabindex="0"
        v-model="yuYueForm.mianJi"
        placeholder="请输入您的房屋面积"
      ></el-input>
    </el-form-item>
    <el-form-item prop="xingMing">
      <el-input
        v-model="yuYueForm.xingMing"
        placeholder="请输入您的姓名"
      ></el-input>
    </el-form-item>
    <el-form-item prop="dianHua">
      <el-input
        v-model="yuYueForm.dianHua"
        placeholder="请输入您的联系电话"
      ></el-input>
    </el-form-item>
    <el-button :disabled="yuYueForm.submiting" @click="submitForm('yuYueForm')" type="success">立即预约</el-button>
    </el-form>
    
  </el-dialog>
</template>

<script>
import ReservationLogAPI from "../api/reservationLogAPI";
import { Message } from "element-ui";

export default {
  name: 'YuYueDialog',
  /**
   * value = {
        visible:false,//窗口弹出状态
        id:0,//设计师id或施工队id
        type:0//0:首页,1 设计师,2施工队,3 优选整装
      }
   */
  props: ['value'],
  methods:{
    submitForm(formName) {
      console.log(this.value);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$data.yuYueForm.submiting = true;
          ReservationLogAPI.create(
            this.value.id,
            this.value.type, 
            this.$data.yuYueForm.mianJi, 
            this.$data.yuYueForm.xingMing, 
            this.$data.yuYueForm.dianHua)
          .then(result =>{
            console.log(result);
            setTimeout(() => {
              this.$data.yuYueForm.submiting = false;
            }, 1000);
            if(result.status == 200 && result.data.code == 0){
              Message.success("预约成功！",{duration:0});
              this.value.visible = false;
            }else{
              Message.error(result.data.msg);
            }
          });
        } else {
          console.log("error submit!!");
          setTimeout(() => {
              this.$data.yuYueForm.submiting = false;
            }, 1000);
          return false;
        }
      });
    },
  },
  data() {
    return {
      yuYueForm: {
        mianJi: null,
        xingMing: null,
        dianHua: null,
        submiting:false,
      },
      yuYueRules: {
        mianJi: [
          { required: true, message: "请输入您的房屋面积", trigger: "blur" },
        ],
        xingMing: [
          { required: true, message: "请输入您的姓名", trigger: "blur" },
        ],
        dianHua: [
          { required: true, message: "请输入您的联系电话", trigger: "blur" },
        ],
      },
      id:0
    }
  }
}
</script>

<style lang="scss">
.yuYueDialog {
  .el-message {
    min-width: 0.38rem;
  }
  .el-message__content{
    font-size: 0.2rem;
  }
  .el-dialog__title {
    font-weight: 500;
    font-size: 0.3rem;
    color: #333333;
  }
  .el-dialog__close {
     font-size: 0.3rem;
  }
  .el-dialog__headerbtn{
    top: 0.3rem;
    right: 0.3rem;
  }
  .el-dialog__header {
    padding: 0.3rem 0 0.1rem 0.3rem;
  }
  .el-dialog__body {
    padding: 0.1rem 0.3rem 0.3rem 0.3rem;
  }
  .el-form-item__content {
    line-height: 0.68rem;
  }
  .el-form-item {
    margin-bottom: 0.32rem;
  }
  .el-form-item__error {
    padding-top: 0.04rem;
    font-size: 0.15rem;
  }
  .el-input__inner {
    height: 0.63rem;
    border-radius: 0px;
    font-size: 0.17rem;
  }
  .el-button {
    border-radius: 0px;
    background: #71be33;
    padding: 0px;
    width: 100%;
    height: 0.63rem;
    font-size: 0.17rem;
    display: block;
  }
}
</style>
