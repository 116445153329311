<template>
  <el-dialog
    class="QRCodeDialog"
    title="小程序码"
    :visible.sync="value.visible"
    width="6rem">
    
    <el-image class="img" 
              :src="imgUrls[value.type]"
            />
    
  </el-dialog>
</template>

<script>
import ReservationLogAPI from "../api/reservationLogAPI";
import { Image, Message } from "element-ui";

export default {
  name: 'QRCodeDialog',
  /**
   * value = {
        visible:false,//窗口弹出状态
        type:1,//1小程序二维码，2AI免费设计
        imgUrl:"" //如果传了imgUrl直接显示imgUrl的二维码
        title:""  //如果传了title直接显示给定的title
      }
   */
  props: ['value'],
  methods:{
    
  },
  data() {
    return {
      imgUrls:['','/img/img10.png','/img/img10.png']
    }
  }
}
</script>

<style lang="scss">
.QRCodeDialog {
  .img{
    width: 4rem;
  }
  .el-message {
    min-width: 0.38rem;
  }
  .el-message__content{
    font-size: 0.2rem;
  }
  .el-dialog__title {
    font-weight: 500;
    font-size: 0.3rem;
    color: #333333;
  }
  .el-dialog__close {
     font-size: 0.3rem;
  }
  .el-dialog__headerbtn{
    top: 0.3rem;
    right: 0.3rem;
  }
  .el-dialog__header {
    padding: 0.3rem 0 0.1rem 0.3rem;
  }
  .el-dialog__body {
    padding: 0.1rem 0.3rem 0.3rem 0.3rem;
    text-align: center;
  }
  .el-form-item__content {
    line-height: 0.68rem;
  }
  .el-form-item {
    margin-bottom: 0.32rem;
  }
  .el-form-item__error {
    padding-top: 0.04rem;
    font-size: 0.15rem;
  }
  .el-input__inner {
    height: 0.63rem;
    border-radius: 0px;
    font-size: 0.17rem;
  }
  .el-button {
    border-radius: 0px;
    background: #71be33;
    padding: 0px;
    width: 100%;
    height: 0.63rem;
    font-size: 0.17rem;
    display: block;
  }
}
</style>
